<div [ngClass]="{ active: docsEditable }" class="files-preview-container">
  <div class="row">
    <div
      *ngFor="let file of files; let i = index"
      [ngClass]="{ 'col-md-6': cols == 2 }"
      class="col-12"
    >
      <div class="file-item">
        <div class="file-details">
          <div class="file-icon">
            <img
              *ngIf="fileType(file.name) == 'doc'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'jpg'"
              src="./assets/images/file-icons/file-jpg-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'pdf'"
              src="./assets/images/file-icons/file-pdf-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'png'"
              src="./assets/images/file-icons/file-png-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'xls'"
              src="./assets/images/file-icons/file-xls-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'zip'"
              src="./assets/images/file-icons/file-zip-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'pptx'"
              src="./assets/images/file-icons/file-ppt-icon.svg"
            />
            <!--  -->
            <img
              *ngIf="fileType(file.name) == 'gif'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'tiff'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'bmp'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
          </div>
          <div>
            <label [title]="file?.name">{{ file?.name }}</label>
            <span *ngIf="file?.size">{{ formatBytes(file?.size) }}</span>
            <!-- <span>{{ file.size }}</span> -->
          </div>
        </div>
        <div class="file-btn">
          <a
            class="download"
            (click)="onDocumentDownloader(file.download, file.name)"
            *ngIf="!downloadWithApi"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="download_ic" d="M169-786.978a1.245,1.245,0,0,1-.422-.07.987.987,0,0,1-.366-.239l-4.05-4.05a1.03,1.03,0,0,1-.323-.787,1.186,1.186,0,0,1,.323-.787,1.147,1.147,0,0,1,.8-.352,1.047,1.047,0,0,1,.8.323l2.109,2.109v-8.044a1.089,1.089,0,0,1,.323-.8A1.088,1.088,0,0,1,169-800a1.088,1.088,0,0,1,.8.323,1.089,1.089,0,0,1,.323.8v8.044l2.109-2.109a1.047,1.047,0,0,1,.8-.323,1.147,1.147,0,0,1,.8.352,1.186,1.186,0,0,1,.323.787,1.03,1.03,0,0,1-.323.787l-4.05,4.05a.987.987,0,0,1-.366.239A1.245,1.245,0,0,1,169-786.978ZM162.25-782a2.167,2.167,0,0,1-1.589-.661A2.167,2.167,0,0,1,160-784.25v-2.25a1.089,1.089,0,0,1,.323-.8,1.088,1.088,0,0,1,.8-.323,1.088,1.088,0,0,1,.8.323,1.089,1.089,0,0,1,.323.8v2.25h13.5v-2.25a1.089,1.089,0,0,1,.323-.8,1.088,1.088,0,0,1,.8-.323,1.088,1.088,0,0,1,.8.323,1.089,1.089,0,0,1,.323.8v2.25a2.167,2.167,0,0,1-.661,1.589,2.167,2.167,0,0,1-1.589.661Z" transform="translate(-160 800)" fill="#666"/>
          </svg>
          
          </a>
          <a
            class="download"
            (click)="download(file.download, file.name)"
            *ngIf="downloadWithApi"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="download_ic" d="M169-786.978a1.245,1.245,0,0,1-.422-.07.987.987,0,0,1-.366-.239l-4.05-4.05a1.03,1.03,0,0,1-.323-.787,1.186,1.186,0,0,1,.323-.787,1.147,1.147,0,0,1,.8-.352,1.047,1.047,0,0,1,.8.323l2.109,2.109v-8.044a1.089,1.089,0,0,1,.323-.8A1.088,1.088,0,0,1,169-800a1.088,1.088,0,0,1,.8.323,1.089,1.089,0,0,1,.323.8v8.044l2.109-2.109a1.047,1.047,0,0,1,.8-.323,1.147,1.147,0,0,1,.8.352,1.186,1.186,0,0,1,.323.787,1.03,1.03,0,0,1-.323.787l-4.05,4.05a.987.987,0,0,1-.366.239A1.245,1.245,0,0,1,169-786.978ZM162.25-782a2.167,2.167,0,0,1-1.589-.661A2.167,2.167,0,0,1,160-784.25v-2.25a1.089,1.089,0,0,1,.323-.8,1.088,1.088,0,0,1,.8-.323,1.088,1.088,0,0,1,.8.323,1.089,1.089,0,0,1,.323.8v2.25h13.5v-2.25a1.089,1.089,0,0,1,.323-.8,1.088,1.088,0,0,1,.8-.323,1.088,1.088,0,0,1,.8.323,1.089,1.089,0,0,1,.323.8v2.25a2.167,2.167,0,0,1-.661,1.589,2.167,2.167,0,0,1-1.589.661Z" transform="translate(-160 800)" fill="#666"/>
          </svg>
          
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
