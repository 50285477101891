import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { OffersListModel, offerSortList, Offer } from '../../../features/panel/models/offers';
import { Vendor } from '../../../features/panel/models/vendors';
import { VendorsService } from '../../../features/panel/services/vendors.service';
import { ConfigService } from '../../../helpers/config.service';


@Component({
  selector: 'app-vendor-offers',
  templateUrl: './vendor-offers.component.html',
  styles: ``
})
export class VendorOffersComponent implements OnInit {
  // SelectedOffer!: string

  dataLoading = false;
  showSearch = false;
  showFilter = false;
  filterModel: OffersListModel = new OffersListModel();
  sortItems = [
    { name: this._config._translate.instant('offer.Most redeemed'), value: offerSortList.most_redeemed },
    { name: this._config._translate.instant('offer.Most claimed'), value: offerSortList.most_claimed },
    { name: this._config._translate.instant('offer.Newest to oldest'), value: offerSortList.Newest_To_Oldest },
    { name: this._config._translate.instant('offer.Nearest to my location'), value: offerSortList.Nearest_To_My_Location },
    { name: this._config._translate.instant('offer.Interests'), value: offerSortList.Based_On_My_Interests },
    { name: this._config._translate.instant('offer.Closest to end'), value: offerSortList.Nearest_To_End }
  ];

  OfferTypes: any[] = [
    { name: this._config._translate.instant("offer.All type"), value: '' },
    { name: this._config._translate.instant("offer.Percentage offers"), value: "percentage" },
    { name: this._config._translate.instant("offer.Amount offers"), value: "fixed_amount" },
    { name: this._config._translate.instant("offer.Cashback"), value: "cashback" },
    { name: this._config._translate.instant("offer.Free gift"), value: "gift" }
  ]


  filterFormGroup!: FormGroup
  total: number = 0
  OffersList: Offer[] = []
  lastSortSelected!: offerSortList
  VendorDetails!: Vendor

  SelectedOffer!: string
  SelectedVendor!: string

  constructor(private _vendorService: VendorsService,
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _config: ConfigService) {


  }

  ngOnInit(): void {
    this.filterModel.perPage = 12;
    this.filterFormGroup = this._formBuilder.group({
      type: [null]
    })
    this._activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.filterModel.vendor_id = params['id'];
        this.GetAllOffers();
        this.GetVendorDetails(params['id'])
      }
    });
  }


  GetAllOffers() {
    this.dataLoading = true;
    let model = { ...this.filterModel }
    model.page += 1;
    this._vendorService.GetVendorOffers(model).subscribe({
      next: (res) => {
        this.dataLoading = false;
        this.OffersList = res.data.list;
        this.total = res.data.total;
        this.lastSortSelected = this.filterModel.sort;
      },
      error: (error) => {
        this.dataLoading = false;

      },
    })
  }



  GetVendorDetails(vendorId: string) {
    this._vendorService.GetVendorDetails(vendorId).subscribe({
      next: (res) => {
        this.VendorDetails = res.data;
      },
      error: (error) => {

      },
    })
  }



  OnSelectSortType(type: any) {
    if (type === offerSortList.Nearest_To_My_Location) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {

            console.log('Latitude:', position.coords.latitude);
            console.log('Longitude:', position.coords.longitude);
            this.filterModel.latitude = position.coords.latitude;
            this.filterModel.longitude = position.coords.longitude;
            this.GetAllOffers()
          },
          (error) => {
            console.error('Error getting location:', error);
            this.filterModel.sort = this.lastSortSelected;
            this.ShowError(this._config._translate.instant("offer.Your location has not been determined yet"), this._config._translate.instant("offer.Please allow the geolocation"));

          }
        );
      } else {
        this.filterModel.sort = this.lastSortSelected;
        this.ShowError(this._config._translate.instant("offer.Geolocation not supported by this browser!"));
      }

    } else {
      this.GetAllOffers();
    }
  }

  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      // if (this.filterModel.search) {
      this.GetAllOffers();
      // }
    }
  }

  resetSearch() {
    this.filterModel.search = '';
    this.GetAllOffers();
  }


  makeFilter() {
    this.filterModel.type = this.filterFormGroup.value.type ? this.filterFormGroup.value.type : ''
    this.filterModel.page = 0;
    this.GetAllOffers();
  }

  clearFilter() {
    this.filterModel.page = 0;
    this.filterModel.type = '';
    this.filterFormGroup.reset();
    this.GetAllOffers();
  }


  //pagination Transactions
  handlePageEvent(event: PageEvent) {
    this.filterModel.perPage = event.pageSize;
    this.filterModel.page = event.pageIndex;
    this.GetAllOffers();
  }



  ShowError(title: string, message?: string) {
    Swal.fire({
      title: this._config._translate.instant('swalMsg.Failed'),
      titleText: title,
      text: message,
      icon: 'error',
      confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
    })
  }


}
