<div class="dialog">
    <ng-container *ngIf="!success">
        <div class="dialog_content" mat-dialog-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="105" height="96" viewBox="0 0 105 96">
                <g id="Group_14638" data-name="Group 14638" transform="translate(0 -20.913)">
                    <g id="Group_14636" data-name="Group 14636" transform="translate(20.028 37.973)">
                        <path id="Path_88965" data-name="Path 88965"
                            d="M113.829.911l3.259,1.655a.747.747,0,0,0,.69-.007L121,.833a1.493,1.493,0,0,1,1.955.5l2,3.063a.747.747,0,0,0,.6.339l3.653.117a1.493,1.493,0,0,1,1.443,1.412l.2,3.65a.747.747,0,0,0,.352.594l3.105,1.928a1.493,1.493,0,0,1,.544,1.944l-1.654,3.259a.746.746,0,0,0,.008.69l1.725,3.222a1.493,1.493,0,0,1-.5,1.955l-3.062,2a.746.746,0,0,0-.339.6l-.117,3.653a1.493,1.493,0,0,1-1.412,1.443l-3.983-.393a.746.746,0,0,0-.594.352l-1.779,2.477a1.493,1.493,0,0,1-1.944.544l-3.169-.968a.746.746,0,0,0-.69.007L114.318,34.8a1.493,1.493,0,0,1-1.955-.5l-2.023-3a.747.747,0,0,0-.6-.339l-3.372.18a1.493,1.493,0,0,1-1.443-1.412l.028-3.7a.746.746,0,0,0-.352-.594l-3.079-1.824a1.493,1.493,0,0,1-.544-1.944l1.348-3.081a.746.746,0,0,0-.008-.69l-1.581-3.247a1.493,1.493,0,0,1,.5-1.955l2.61-1.885a.746.746,0,0,0,.339-.6l.117-3.653a1.493,1.493,0,0,1,1.412-1.443l3.65-.2a.746.746,0,0,0,.594-.352l1.928-3.105a1.493,1.493,0,0,1,1.944-.544Z"
                            transform="translate(-100.107 -0.656)" fill="#df646e" />
                        <path id="Path_88966" data-name="Path 88966"
                            d="M127.457,103.762l-.021.668a1.493,1.493,0,0,1-1.411,1.443l-3.65.2a.743.743,0,0,0-.593.351l-1.929,3.105a1.493,1.493,0,0,1-1.944.544l-3.258-1.655a.745.745,0,0,0-.69.007l-3.223,1.726a1.494,1.494,0,0,1-1.955-.5l-2-3.062a.744.744,0,0,0-.6-.339l-3.652-.118a1.494,1.494,0,0,1-1.444-1.411l-.2-3.65a.751.751,0,0,0-.353-.595l-3.105-1.928a1.494,1.494,0,0,1-.544-1.944l1.655-3.26a.745.745,0,0,0-.007-.69l-1.726-3.223a1.494,1.494,0,0,1,.5-1.955l3.063-2a.753.753,0,0,0,.312-.426,21.889,21.889,0,0,0,26.768,18.71Z"
                            transform="translate(-96.637 -75.328)" fill="#dc4955" />
                        <path id="Path_88967" data-name="Path 88967"
                            d="M168.755,59.629a12.1,12.1,0,0,1-7.536,11.208c-1.408.574-2.7.306-4.309.306-6.682,0-12-4.833-12-11.515a12.847,12.847,0,0,1-.187-1.979,12.1,12.1,0,0,1,24.035,1.979Z"
                            transform="translate(-139.16 -42.13)" fill="#f9af38" />
                        <path id="Path_88968" data-name="Path 88968"
                            d="M160.159,148.539a12.1,12.1,0,0,1-16.5-13.187A21.894,21.894,0,0,0,160.159,148.539Z"
                            transform="translate(-138.101 -119.833)" fill="#f48931" />
                        <path id="Path_88969" data-name="Path 88969"
                            d="M192.73,104.844a.864.864,0,0,1-.8-1.2l4.641-11.058a.864.864,0,1,1,1.594.669l-4.641,11.058A.865.865,0,0,1,192.73,104.844Zm-1.81-6.808a3.266,3.266,0,1,1,2.915-3.247A3.1,3.1,0,0,1,190.921,98.037Zm0-4.766a1.565,1.565,0,1,0,1.186,1.519A1.385,1.385,0,0,0,190.921,93.271Zm8.111,12.326a3.266,3.266,0,1,1,2.915-3.247A3.1,3.1,0,0,1,199.031,105.6Zm0-4.765a1.565,1.565,0,1,0,1.186,1.519A1.385,1.385,0,0,0,199.031,100.831Z"
                            transform="translate(-177.479 -81.071)" fill="#f5fcff" />
                    </g>
                    <path id="Path_88953" data-name="Path 88953"
                        d="M124.5,269.4H81.345a2.039,2.039,0,0,1-2.039-2.039V247.736a2.039,2.039,0,0,1,2.039-2.039H124.5a2.039,2.039,0,0,1,2.039,2.039v19.619A2.039,2.039,0,0,1,124.5,269.4Z"
                        transform="translate(-63.59 -179.182)" />
                    <path id="Path_88954" data-name="Path 88954"
                        d="M122.758,60.911a1.98,1.98,0,0,1-1.98-1.98V55.149a1.98,1.98,0,0,1,3.959,0v3.782A1.98,1.98,0,0,1,122.758,60.911Zm-8.6,1.485a1.981,1.981,0,0,1-1.861-1.3L111,57.538a1.98,1.98,0,0,1,3.721-1.353l1.292,3.554a1.98,1.98,0,0,1-1.86,2.657Zm-7.574,4.339a1.974,1.974,0,0,1-1.518-.708l-2.429-2.9a1.98,1.98,0,1,1,3.034-2.544l2.429,2.9a1.98,1.98,0,0,1-1.516,3.252ZM100.946,73.4a1.972,1.972,0,0,1-.988-.266l-3.273-1.891a1.98,1.98,0,1,1,1.981-3.428l3.272,1.891a1.98,1.98,0,0,1-.993,3.694Zm43.761-.189a1.98,1.98,0,0,1-.993-3.694l3.272-1.891a1.98,1.98,0,1,1,1.981,3.428L145.7,72.949A1.968,1.968,0,0,1,144.708,73.215Zm-5.722-6.771a1.98,1.98,0,0,1-1.516-3.252l2.429-2.9a1.98,1.98,0,1,1,3.034,2.544l-2.429,2.9A1.975,1.975,0,0,1,138.986,66.444Zm-7.687-4.4a1.98,1.98,0,0,1-1.86-2.657l1.292-3.554a1.98,1.98,0,1,1,3.721,1.353l-1.292,3.554A1.98,1.98,0,0,1,131.3,62.04Z"
                        transform="translate(-86.306 -32.256)" fill="#fad87c" />
                    <path id="Path_88977" data-name="Path 88977"
                        d="M13191.68-12281.429v0l-31.21-15.91h0v0l-45.164-1.675-16.847-2.319c-.06-.01-.173-.022-.329-.042l-.048,0a36.578,36.578,0,0,1-4.578-.783c-2.218-.567-3.395-1.285-3.5-2.132v-1.4c.115.843,1.294,1.557,3.5,2.12a35.942,35.942,0,0,0,4.572.782h.006l.069.008.3.039,16.847,2.319,45.165,1.674,31.211,15.91v1.407Zm-56.106-29.361h0a1.767,1.767,0,0,0-1.1-.446l-14.743-1.3c-.355-.03-.749-.052-1.226-.078-1.649-.085-3.7-.192-4.734-.986a2.514,2.514,0,0,1-.537-2.393,1.744,1.744,0,0,0,.537.985c1.033.793,3.084.9,4.731.986.479.025.873.047,1.229.077l14.743,1.3a1.5,1.5,0,0,1,1.334.779,1.035,1.035,0,0,1-.228,1.075Z"
                        transform="translate(-13090.001 12397.565)" fill="#f1c696" />
                    <path id="Path_88976" data-name="Path 88976"
                        d="M79.461,276.851a15.507,15.507,0,0,1-6.948-5.3,7.968,7.968,0,0,0-6.4-3.085l-10.014-.09a14.314,14.314,0,0,0-5.962,1.233l-2.433,1.085c-1.512.677-5.246,2.263-6.927,2.38l-7.365.845c-11.15-1.315-10.958,5-9.65,6.008s4.186.907,5.959,1.063l14.742,1.3c2.189.191,2.111,2.874-2.373,2.97l-15.43.546-15.7-1.315C2.062,282.793,0,284.972,0,287.741v1.489c.272,2.209,7.8,2.857,8.455,2.96L25.3,294.51l45.166,1.674v0l31.211,15.911V285.4Z"
                        transform="translate(0 -197.364)" fill="#f2d1a5" />
                    <path id="Path_88956" data-name="Path 88956" d="M481.1,342.369V368.3l6.124,3.129v-26.7Z"
                        transform="translate(-385.542 -256.697)" fill="#f2bb88" />
                    <path id="Path_88978" data-name="Path 88978"
                        d="M0,9.54.28,3.25A2.738,2.738,0,0,1,3.017.512L4.956,0h27A2.738,2.738,0,0,1,34.7,2.738V7.411l-.644,2.048A2.738,2.738,0,0,1,31.314,12.2l-28.577.082A2.738,2.738,0,0,1,0,9.54Z"
                        transform="translate(92.113 116.394) rotate(-90)" fill="#5f6c75" />
                    <path id="Path_88979" data-name="Path 88979"
                        d="M35.215,7.411v2.738a2.738,2.738,0,0,1-2.738,2.738H2.738A2.738,2.738,0,0,1,0,10.149V2.738A2.738,2.738,0,0,1,2.738,0H5.475V6.316a1.1,1.1,0,0,0,1.1,1.1Z"
                        transform="translate(92.114 116.913) rotate(-90)" fill="#4e5a61" />
                    <path id="Path_88957" data-name="Path 88957"
                        d="M335.819,38.59l-5.65,23.136a11.744,11.744,0,0,1-14.2,8.643L310.64,69.06a.817.817,0,0,1-.6-.988L320.9,23.625a.814.814,0,0,1,.985-.6l5.323,1.309A11.79,11.79,0,0,1,335.819,38.59Z"
                        transform="translate(-248.415 -0.619)" />
                    <path id="Path_88958" data-name="Path 88958"
                        d="M350.16,24.334l-5.323-1.309a.814.814,0,0,0-.985.6l-.153.627.337.083a11.79,11.79,0,0,1,8.613,14.255L347,61.725a11.764,11.764,0,0,1-8.352,8.576l.272.067a11.744,11.744,0,0,0,14.2-8.643l5.65-23.136a11.79,11.79,0,0,0-8.613-14.255Z"
                        transform="translate(-271.365 -0.618)" fill="rgba(255,255,255,0.13)" />
                </g>
            </svg>



            <h3>{{"offer.Are you sure to redeem the offer?"|translate}}</h3>




        </div>
        <div class="dialog_actions justify-content-center" mat-dialog-actions>
            <button mat-button class="btn-primary" (click)="OnConfirm()" [class.loading]="loading" [disabled]="loading">
                {{"offer.Yes. I'm sure."|translate}}</button>
            <button mat-button class="btn-secondary" mat-dialog-close> {{"offer.Cancel"|translate}}</button>
        </div>
    </ng-container>


    <ng-container *ngIf="success">
        <div class="dialog_content" mat-dialog-content>

            <!-- <img src="/src/assets/js/redeem_success.json" alt="Offer Redeemed Image"> -->

            <empty-state [animationPath]="'./assets/js/redeem_success.json'" [loop]="true"></empty-state>

            <h3>{{"offer.Offer successfully redeemed"|translate}}</h3>

            <p>{{"offer.Now all you need to do is visit the nearest vendor branch to claim the offer"|translate}}</p>




        </div>
        <div class="dialog_actions justify-content-center flex-column align-items-center" mat-dialog-actions>
            <button mat-button class="btn-primary" (click)="OnViewCode()">
                {{"offer.View code"|translate}}</button>
            <button mat-button class="btn-secondary m-0" (click)="dialogRef.close(true)">
                {{"general.close"|translate}}</button>
        </div>
    </ng-container>

</div>