export class ListingModel {
    search: string = '';
    page: number = 0;
    perPage: number = 10;
}


export enum sortList {
    ASC = "ASC",
    DESC = 'DESC',
}

export enum sortBy {
    CreatedAt = "created_at",
    Name = 'name'
}

export class EmployeePointsHistory {
    month!: number
    yeay!: number
    year_and_month!: string
    dataList!: EmployeePointsByMonth[]
    loading!: boolean
    pagination!: any
    page: number = 1;
    perPage: number = 10
}

export interface EmployeePointsByMonth {
    id: string
    created_at: string
    num_points: number
    type: employeeType
    message: Message
    arrow: string
    users: User[]
    isShow: boolean
    redeem_data: any
    timer: any
}


export class Message {
    title!: string
    expire_after_seconds: any
    sub_title!: string
    redeem_data!: any
    status!: any
}

export class User {
    num_points!: number
    name_ar!: string
    name_en!: string
    branch_name_en!: string
    branch_name_ar!: string
    image!: string
    full_name!: string
}

export enum employeeType {
    emp_to_family = 'emp_to_family',
    emp_to_emp = 'emp_to_emp',
    company_to_emp = 'company_to_emp',
    withdraw_points = 'withdraw_points',
    points_retrun_from_deleted_user = 'points_retrun_from_deleted_user'
}


export class UsersListModel extends ListingModel {
    type: 'family' | 'employees' = 'employees'
}