import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VendorsService } from '../../../features/panel/services/vendors.service';

@Component({
  selector: 'app-vendor-branches',
  templateUrl: './vendor-branches.component.html',
  styles: ``
})
export class VendorBranchesComponent implements OnInit {
  loading: boolean = false;
  loading_more: boolean = false;
  have_more_data: boolean = true;
  branches: any[] = [];
  total: number = 0;
  listModel: any = { page: 1, perPage: 10 };
  constructor(private _vendorService: VendorsService,
    public dialogRef: MatDialogRef<VendorBranchesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.listModel.vendor_id = this.data;
    this.GetBranchers()
  }


  GetBranchers(load_more: boolean = false): void {
    if (!this.have_more_data) {
      return
    }
    if (load_more) {
      this.loading_more = true;
    } else {
      this.loading = true;
    }
    this._vendorService.GetVendorBranches(this.listModel).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.loading_more = false;
        this.have_more_data = (res.data.list?.length == this.listModel.perPage)
        this.branches = this.branches.concat(res.data.list);
        this.total = res.data.total;
      },
      error: (error: any) => {
        this.loading = false;
        this.loading_more = false;
      },
    });
  }


  getNextPage() {
    this.listModel.page += 1;
    this.GetBranchers(true);
  }

}
