import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Offer } from '../../features/panel/models/offers';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styles: ``
})
export class OfferCardComponent implements OnInit {

  @Input() OfferDetailes: Offer | undefined;
  @Input() ShowDistance: boolean = false;
  @Input() ShowFavorite: boolean = false;

  @Output() OpenOffer = new EventEmitter();
  @Output() OpenVendor = new EventEmitter();
  SelectedOffer!: string
  SelectedVendor!: string
  constructor() { }

  ngOnInit(): void {

  }

  OnOpenOffer() {
    if (this.OfferDetailes?.offer_deleted) {
      return;
    }
    this.OpenOffer.emit(this.OfferDetailes?.offer_id)
    // this.SelectedOffer = this.OfferDetailes?.offer_id || '';
    // this.SelectedVendor = ''
  }

  OnOpenVendor(event: Event) {
    event.stopPropagation();
    if (this.OfferDetailes?.offer_deleted) {
      return;
    }
    this.OpenVendor.emit(this.OfferDetailes?.vendor_id)

    // this.SelectedVendor = this.OfferDetailes?.vendor_id || '';
    // this.SelectedOffer = '';

  }

}
