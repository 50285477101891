import { ListingModel } from "./general";

export enum offerLortList {
    ASC = "ASC",
    DESC = 'DESC',
    created_at = 'created_at',
    claimed = 'claimed',
    redeemed = 'redeemed'
}


export class OffersListModel extends ListingModel {
    sort: offerSortList = offerSortList.most_redeemed;
    category_ids_array: string[] = [];
    type!: string;
    latitude!: number;
    longitude !: number;
    vendor_id!: string;
}

export enum offerSortList {
    most_claimed = "most_claimed",
    most_redeemed = "most_redeemed",
    Newest_To_Oldest = 'newest_to_oldest',
    Nearest_To_My_Location = 'nearest_to_my_location',
    Based_On_My_Interests = 'based_on_my_interests',
    Nearest_To_End = 'nearest_to_end'
}


export interface Offer {
    offer_id: string
    vendor_id: string
    name: string
    image: string
    for_all_branches: boolean
    number_of_branches: number
    distance: number
    favorite: boolean
    type: {
        name: string
        code: string
        amount: string
    }
    vendor: {
        name: string
        logo: string
    }
    user_use_offer: boolean
    used_offer_info: {
        code: string
        status: string
        date: string
        offersApprovedAt: string
    }
    start_at: string
    end_at: string
    offer_end: boolean
    offer_deleted: boolean
    rate: any
    my_rate: any
    rate_count: number
}
