import { Component, OnInit, Input } from '@angular/core';
// import { EmployeeService } from '../../features/companies-users/services/employee.service';
import { ApiServiceService } from '../../services/api-service.service';
import saveAs from 'file-saver';
import { environment } from '../../../environments/environment';
// import { saveAs } from 'file-saver';
// import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-files-viewer',
  templateUrl: './files-viewer.component.html',
})
export class FilesViewerComponent implements OnInit {
  @Input() files: any = [];
  @Input() cols = 1;
  @Input() downloadWithApi = false;
  docsEditable = false;
  requestToken!: string;

  constructor(
    private _ApiService: ApiServiceService,
  ) {

  }

  ngOnInit() {
  }
  fileType(fileName: any) {
    const extension = fileName?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      case 'ppt':
      case 'pptx':
        return 'pptx';

      case 'gif':
        return 'gif';
      case 'tiff':
        return 'tiff';
      case 'bmp':
        return 'bmp';
      default:
        return false;
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  download(filePath: any, fileName: any) {
    let model = { file_download: filePath }
    this._ApiService.downloadFile(`${environment.apiUrl}/download-file`,model).subscribe((data: any) => {
      const blob = new Blob([data]);
      console.log(blob);
      saveAs(blob, `${fileName}`);
    });
  }
  onDocumentDownloader(file: any, fileName: any) {
    // const model = {
    //   file_download: filePath,
    // };

    const blob = new Blob([file]);
    console.log(blob);
    saveAs(blob, `${fileName}`);

    // this._employeeService.saveAsExcelFile(file, fileName)
  }
}
