import * as i0 from '@angular/core';
import { isDevMode, Directive, Input, Component, NgModule } from '@angular/core';
import qrcode from 'qrcode';
import { CommonModule } from '@angular/common';
function QrCodeComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "canvas", 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("qrCode", ctx_r0.value)("qrCodeErrorCorrectionLevel", ctx_r0.errorCorrectionLevel)("qrCodeCenterImageSrc", ctx_r0.centerImageSrc)("qrCodeCenterImageWidth", ctx_r0.centerImageSize)("qrCodeCenterImageHeight", ctx_r0.centerImageSize)("qrCodeMargin", ctx_r0.margin)("width", ctx_r0.size)("height", ctx_r0.size)("darkColor", ctx_r0.darkColor)("lightColor", ctx_r0.lightColor);
  }
}
const validColorRegex = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/;
class QrCodeDirective {
  static {
    this.DEFAULT_ERROR_CORRECTION_LEVEL = "M";
  }
  static {
    this.DEFAULT_CENTER_IMAGE_SIZE = 40;
  }
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    this.errorCorrectionLevel = QrCodeDirective.DEFAULT_ERROR_CORRECTION_LEVEL;
    this.darkColor = "#000000FF";
    this.lightColor = "#FFFFFFFF";
    // eslint-disable-next-line @angular-eslint/no-input-rename
    this.margin = 16;
  }
  async ngOnChanges() {
    if (!this.value) {
      return;
    }
    if (this.version && this.version > 40) {
      console.warn("[qrCode] max version is 40, clamping");
      this.version = 40;
    } else if (this.version && this.version < 1) {
      console.warn("[qrCode] min version is 1, clamping");
      this.version = 1;
    } else if (this.version !== undefined && isNaN(this.version)) {
      console.warn("[qrCode] version should be set to a number, defaulting to auto");
      this.version = undefined;
    }
    const canvas = this.viewContainerRef.element.nativeElement;
    if (!canvas) {
      // native element not available on server side rendering
      return;
    }
    const context = canvas.getContext("2d");
    if (context) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    }
    const errorCorrectionLevel = this.errorCorrectionLevel ?? QrCodeDirective.DEFAULT_ERROR_CORRECTION_LEVEL;
    const dark = validColorRegex.test(this.darkColor) ? this.darkColor : undefined;
    const light = validColorRegex.test(this.lightColor) ? this.lightColor : undefined;
    if (isDevMode()) {
      if (!dark && this.darkColor) {
        console.error("[ng-qrcode] darkColor set to invalid value, must be RGBA hex color string, eg: #3050A1FF");
      }
      if (!light && this.lightColor) {
        console.error("[ng-qrcode] lightColor set to invalid value, must be RGBA hex color string, eg: #3050A130");
      }
    }
    await qrcode.toCanvas(canvas, this.value, {
      version: this.version,
      errorCorrectionLevel,
      width: this.width,
      margin: this.margin,
      color: {
        dark,
        light
      }
    });
    const centerImageSrc = this.centerImageSrc;
    const centerImageWidth = getIntOrDefault(this.centerImageWidth, QrCodeDirective.DEFAULT_CENTER_IMAGE_SIZE);
    const centerImageHeight = getIntOrDefault(this.centerImageHeight, QrCodeDirective.DEFAULT_CENTER_IMAGE_SIZE);
    if (centerImageSrc && context) {
      if (!this.centerImage) {
        this.centerImage = new Image(centerImageWidth, centerImageHeight);
      }
      const centerImage = this.centerImage;
      if (centerImageSrc !== this.centerImage.src) {
        centerImage.src = centerImageSrc;
      }
      if (centerImageWidth !== this.centerImage.width) {
        centerImage.width = centerImageWidth;
      }
      if (centerImageHeight !== this.centerImage.height) {
        centerImage.height = centerImageHeight;
      }
      const doDrawImage = () => {
        context.drawImage(centerImage, canvas.width / 2 - centerImageWidth / 2, canvas.height / 2 - centerImageHeight / 2, centerImageWidth, centerImageHeight);
      };
      centerImage.onload = doDrawImage;
      if (centerImage.complete) {
        doDrawImage();
      }
    }
  }
  static {
    this.ɵfac = function QrCodeDirective_Factory(t) {
      return new (t || QrCodeDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: QrCodeDirective,
      selectors: [["canvas", "qrCode", ""]],
      inputs: {
        value: ["qrCode", "value"],
        version: ["qrCodeVersion", "version"],
        errorCorrectionLevel: ["qrCodeErrorCorrectionLevel", "errorCorrectionLevel"],
        width: "width",
        height: "height",
        darkColor: "darkColor",
        lightColor: "lightColor",
        centerImageSrc: ["qrCodeCenterImageSrc", "centerImageSrc"],
        centerImageWidth: ["qrCodeCenterImageWidth", "centerImageWidth"],
        centerImageHeight: ["qrCodeCenterImageHeight", "centerImageHeight"],
        margin: ["qrCodeMargin", "margin"]
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QrCodeDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: `canvas[qrCode]`
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], {
    value: [{
      type: Input,
      args: ["qrCode"]
    }],
    version: [{
      type: Input,
      args: ["qrCodeVersion"]
    }],
    errorCorrectionLevel: [{
      type: Input,
      args: ["qrCodeErrorCorrectionLevel"]
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    darkColor: [{
      type: Input
    }],
    lightColor: [{
      type: Input
    }],
    centerImageSrc: [{
      type: Input,
      args: ["qrCodeCenterImageSrc"]
    }],
    centerImageWidth: [{
      type: Input,
      args: ["qrCodeCenterImageWidth"]
    }],
    centerImageHeight: [{
      type: Input,
      args: ["qrCodeCenterImageHeight"]
    }],
    margin: [{
      type: Input,
      args: ["qrCodeMargin"]
    }]
  });
})();
function getIntOrDefault(value, defaultValue) {
  if (value === undefined || value === "") {
    return defaultValue;
  }
  if (typeof value === "string") {
    return parseInt(value, 10);
  }
  return value;
}
class QrCodeComponent {
  static {
    this.ɵfac = function QrCodeComponent_Factory(t) {
      return new (t || QrCodeComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: QrCodeComponent,
      selectors: [["qr-code"]],
      inputs: {
        value: "value",
        size: "size",
        darkColor: "darkColor",
        lightColor: "lightColor",
        errorCorrectionLevel: "errorCorrectionLevel",
        centerImageSrc: "centerImageSrc",
        centerImageSize: "centerImageSize",
        margin: "margin"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "qrCode", "qrCodeErrorCorrectionLevel", "qrCodeCenterImageSrc", "qrCodeCenterImageWidth", "qrCodeCenterImageHeight", "qrCodeMargin", "width", "height", "darkColor", "lightColor"]],
      template: function QrCodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, QrCodeComponent_Conditional_0_Template, 1, 10, "canvas", 0);
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.value ? 0 : -1);
        }
      },
      dependencies: [QrCodeDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QrCodeComponent, [{
    type: Component,
    args: [{
      selector: "qr-code",
      template: `
    @if (value) {
      <canvas
        [qrCode]="value"
        [qrCodeErrorCorrectionLevel]="errorCorrectionLevel"
        [qrCodeCenterImageSrc]="centerImageSrc"
        [qrCodeCenterImageWidth]="centerImageSize"
        [qrCodeCenterImageHeight]="centerImageSize"
        [qrCodeMargin]="margin"
        [width]="size"
        [height]="size"
        [darkColor]="darkColor"
        [lightColor]="lightColor"
      >
      </canvas>
    }
  `
    }]
  }], null, {
    value: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    darkColor: [{
      type: Input
    }],
    lightColor: [{
      type: Input
    }],
    errorCorrectionLevel: [{
      type: Input
    }],
    centerImageSrc: [{
      type: Input
    }],
    centerImageSize: [{
      type: Input
    }],
    margin: [{
      type: Input
    }]
  });
})();
class QrCodeModule {
  static {
    this.ɵfac = function QrCodeModule_Factory(t) {
      return new (t || QrCodeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: QrCodeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QrCodeModule, [{
    type: NgModule,
    args: [{
      declarations: [QrCodeComponent, QrCodeDirective],
      imports: [CommonModule],
      exports: [QrCodeComponent, QrCodeDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-qrcode
 */

/**
 * Generated bundle index. Do not edit.
 */

export { QrCodeComponent, QrCodeDirective, QrCodeModule };
