import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from '../../../../helpers/config.service';
import { OffersService } from '../../../../features/panel/services/offers.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-offer-branches',
  templateUrl: './offer-branches.component.html',
  styles: ``
})
export class OfferBranchesComponent implements OnInit {
  loading: boolean = false;
  branchesList: any[] = [];
  constructor(private _config: ConfigService,
    private _offerservice: OffersService,
    public dialogRef: MatDialogRef<OfferBranchesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.GetBranches();
  }

  GetBranches(): void {
    this.loading = true;
    this._offerservice.GetOfferBranches(this.data).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.branchesList = res.data;
      },
      error: (error: any) => { this.loading = false; }
    })
  }
}
