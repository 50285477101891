import { Component, Inject, OnInit } from '@angular/core';
import { OffersService } from '../../../../features/panel/services/offers.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { SocketService, SocketEventType } from '../../../../services/socket.service';
import { ConfigService } from '../../../../helpers/config.service';

@Component({
  selector: 'app-redeem-offer',
  templateUrl: './redeem-offer.component.html',
  styles: ``
})
export class RedeemOfferComponent implements OnInit {

  success: boolean = false;
  loading: boolean = false;
  CodeDetails: any
  subscribtions: Subscription[] = []

  constructor(private _offerService: OffersService,
    public dialogRef: MatDialogRef<RedeemOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _socket: SocketService,
    private _config:ConfigService
  ) { }

  ngOnInit(): void {
    this.subscribtions.push(
      this._socket.getDataStream(SocketEventType.Redeemed_Offers).subscribe((result: any) => {
        if (this.data?.id == result.data.offer_id) {
          this.dialogRef.close();
          // Swal.fire({
          //   title: this._config._translate.instant("offer.The offer has been claimed successfully"),
          //   text: '',
          //   imageUrl: './assets/images/icons/success.svg',
          //   confirmButtonText: this._config._translate.instant('swalMsg.Ok')
          // });
        }
      })
    )
  }


  OnConfirm() {
    // this.success = true;
    this.loading = true;
    this._offerService.RedeemOffer(this.data).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.success = true;
        this.CodeDetails = res.data;
      },
      error: (error: any) => { this.loading = false; },
    }
    )
  }

  OnViewCode() {
    this.dialogRef.close({ view_QR: true, data: this.CodeDetails })
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(element => {
      element.unsubscribe();
    });
  }
}