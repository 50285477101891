import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiServiceService } from '../../../services/api-service.service';
import { formatParams } from '../../../helpers/FormateArrayToParams';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {



  constructor(private _apiService: ApiServiceService) { }

  GetCities(model: any) {
    return this._apiService.get(`${environment.apiUrl}/web/cities`, model)
      .pipe(catchError(this.handleError));
  }

  GetVendors(model: any) {
    let params = formatParams(model)
    return this._apiService.get(`${environment.apiUrl}/web/vendors${params}`)
      .pipe(catchError(this.handleError));
  }

  GetVendorDetails(vendor_id: string) {
    return this._apiService.get(`${environment.apiUrl}/web/vendors/profile?vendor_id=${vendor_id}`)
      .pipe(catchError(this.handleError));
  }

  GetVendorBranches(model: any) {
    return this._apiService.get(`${environment.apiUrl}/web/vendors/branches`, model)
      .pipe(catchError(this.handleError));
  }

  ChangeFavoritestatus(model: any) {
    return this._apiService.post(`${environment.apiUrl}/web/vendors/favorite`, model)
      .pipe(catchError(this.handleError));
  }

  GetVendorOffers(model: any) {
    let params = formatParams(model)
    return this._apiService.get(`${environment.apiUrl}/web/vendors/offers${params}`)
      .pipe(catchError(this.handleError));
  }


  handleError(error: any) {
    return throwError(error);
  }
}
