<div class="timer">
    <div class="title text-center">{{title}}</div>
    <div class="d-flex justify-content-center align-items-center" dir="rtl">
        <div class="seconds">
            <div class="count">{{seconds}}</div>
            <div class="lable">{{"redeemPoints.Second"|translate}}</div>
        </div>
        <div class="divider px-4 my-auto">:</div>
        <div class="minutes">
            <div class="count">{{minutes}}</div>
            <div class="lable">{{"redeemPoints.Minute"|translate}}</div>
        </div>
    </div>
</div>