import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VendorBranchesComponent } from './vendor-branches/vendor-branches.component';
import Swal from 'sweetalert2';
import { Vendor } from '../../features/panel/models/vendors';
import { VendorsService } from '../../features/panel/services/vendors.service';
import { ConfigService } from '../../helpers/config.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styles: ``
})
export class VendorDetailsComponent implements OnInit {

  @Output() close = new EventEmitter(false);
  @Output() RequestChanged = new EventEmitter(false);
  @Input() selectedId!: string;

  dataLoading: boolean = false;

  vendorDetails!: Vendor | null
  mapOptions!: google.maps.MapOptions
  marker!: google.maps.MarkerOptions
  constructor(private _dialog: MatDialog,
    private _vendorService: VendorsService, public _config: ConfigService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.selectedId && this.selectedId) {
      this.GetVendorDetails()
    }
  }

  OnViewBranches() {

    const dialogRef = this._dialog.open(VendorBranchesComponent, {
      width: "540px",
      data: this.selectedId
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    })
  }

  GetVendorDetails() {
    this.dataLoading = true;
    this._vendorService.GetVendorDetails(this.selectedId).subscribe({
      next: (res: any) => {
        this.dataLoading = false;
        this.vendorDetails = res.data;
        this.mapOptions = {
          center: {
            lat: Number(this.vendorDetails?.map_lat),
            lng: Number(this.vendorDetails?.map_lang)
          },
          mapId: 'customMap6',
          scrollwheel: true,
          disableDoubleClickZoom: true,
          mapTypeId: 'roadmap',
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false
        };

        this.marker = {
          position: {
            lat: Number(this.vendorDetails?.map_lat),
            lng: Number(this.vendorDetails?.map_lang)
          },
          clickable: true,
          label: this.vendorDetails?.address ? { text: this.vendorDetails?.address ? this.vendorDetails?.address : '', className: 'map_lable' } : null
        }
      },
      error: (error: any) => {
        this.dataLoading = false;
        this.close.emit(true);
      },
    });
  }


  changeFavoriteStatus() {
    Swal.fire({
      title: this._config._translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this._config._translate.instant('swalMsg.Yes'),
      cancelButtonText: this._config._translate.instant('swalMsg.No')
    }).then((result) => {
      if (result.value) {
        this.dataLoading = true;
        let model = { vendor_id: this.vendorDetails?.id }
        this._vendorService.ChangeFavoritestatus(model).subscribe(
          (res: any) => {
            if (res) {
              Swal.fire({
                title: this._config._translate.instant('swalMsg.Success'),
                text: res?.data,
                icon: 'success',
                confirmButtonText: this._config._translate.instant('swalMsg.Ok')
              });
              this.dataLoading = false;
              this.vendorDetails!.favorite = !this.vendorDetails?.favorite
            } else {
              this.dataLoading = false;
            }
          },
          (error: any) => {
            this.dataLoading = false;
          });
      }
    });
  }

}
