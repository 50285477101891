import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent implements OnInit {

  @Input() title: string = '';
  @Input() desc: string = '';
  @Input() img: string = '';
  @Input() animationPath: string = '';
  @Input() custClass: string = '';
  @Input() loop: boolean = false;

  options: AnimationOptions | null = null;

  constructor() { }

  ngOnInit(): void {
    this.options = {
      path: this.animationPath || './assets/js/empty-state-animated.json',
      loop: this.loop
    };
  }

}
