<div class="dialog">
    <div class="dialog_header">
        <a mat-dialog-close><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </a>
        {{"vendor.Vendor Branches"|translate}}
    </div>
    <div class="dialog_content p-0" mat-dialog-content infiniteScroll [scrollWindow]="false"
        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="listModel.perPage" (scrolled)="getNextPage()">

        <div class="offer_branches" *ngIf="!loading">
            <div class="branch" *ngFor="let item of branches">
                <h4> {{item?.branch_name}}</h4>
                <p><ng-container *ngIf="item.branch_address"> {{item?.branch_address}},</ng-container>
                    {{item?.city_name}}</p>
                <a href="https://www.google.com/maps?q={{item?.map_lat}},{{item?.map_lang}}" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                        <path id="ic_directions"
                            d="M5.514,13.385l-4.9-4.91a2.11,2.11,0,0,1,0-2.973L5.514.59a2.164,2.164,0,0,1,2.971,0l4.9,4.912a2.11,2.11,0,0,1,0,2.973l-4.9,4.91a2.105,2.105,0,0,1-2.971,0ZM6.5,1.578,1.6,6.49a.714.714,0,0,0-.209.494.722.722,0,0,0,.209.5l4.9,4.908a.694.694,0,0,0,.993,0l4.9-4.908a.712.712,0,0,0,.207-.5A.7.7,0,0,0,12.4,6.49L7.5,1.578a.7.7,0,0,0-.993,0ZM7.555,8.891a.7.7,0,0,1,0-1l.21-.2H5.951v.7a.7.7,0,1,1-1.4,0V6.984a.7.7,0,0,1,.7-.7H7.765l-.21-.2a.706.706,0,0,1,1-1l1.4,1.4a.7.7,0,0,1,.207.494.712.712,0,0,1-.207.5l-1.4,1.4a.728.728,0,0,1-.5.207A.717.717,0,0,1,7.555,8.891Z"
                            transform="translate(0 0)" fill="#828398" />
                    </svg>
                    {{"vendor.Directions on the map"|translate}}
                </a>
            </div>

            <div *ngIf='loading_more' class="dataLoading">
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>
        </div>

        <div *ngIf='loading' class="dataLoading">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>

    </div>

    <div class="dialog_actions justify-content-end" mat-dialog-actions>
        <button mat-button class="btn-secondary" mat-dialog-close>
            {{"general.close"|translate}}</button>
    </div>
</div>