import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styles: ``
})
export class CountDownComponent {
  private timer$ = interval(1000);
  // private timeLeft = 5 * 60; // 5 minutes
  @Input({ required: true }) timeLeft: number = 0;
  @Input() title!: string;
  @Output() OnEnd = new EventEmitter();
  @Output() CurrentCount = new EventEmitter();

  subscribe_interval!: Subscription
  minutes: number = 0;
  seconds: number = 0;
  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.timeLeft) {
      this.subscribe_interval = this.timer$.subscribe(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          this.minutes = Math.floor(this.timeLeft / 60);
          this.seconds = this.timeLeft % 60;
          this.CurrentCount.emit(this.timeLeft)
        } else {
          this.OnEnd.emit(true)
          this.subscribe_interval.unsubscribe();
        }

      });
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribe_interval.unsubscribe();
  }
}
