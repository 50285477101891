<div class="main_card" (click)="OnOpenOffer()" [class.cursor_notallowed]='OfferDetailes?.offer_deleted'>
    <div class="cover_img">
        <img [src]="OfferDetailes?.image" (error)="OfferDetailes!.image = ''"
            onerror="this.src='./assets/images/defaults/offer_card_default.svg'" alt='Offer Cover Image'>
    </div>

    @switch (OfferDetailes?.type?.code) {
    @case ('percentage') {
    <div class="offer_type">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="icon" transform="translate(-138)">
                <g id="ic_percentage_discount" transform="translate(138)">
                    <rect id="container" width="20" height="20" opacity="0" />
                    <path id="percentage_discount_ic"
                        d="M163.063-793.875a2.951,2.951,0,0,1-2.166-.9,2.952,2.952,0,0,1-.9-2.166,2.949,2.949,0,0,1,.9-2.165,2.949,2.949,0,0,1,2.166-.9,2.949,2.949,0,0,1,2.166.9,2.949,2.949,0,0,1,.9,2.165,2.951,2.951,0,0,1-.9,2.166A2.952,2.952,0,0,1,163.063-793.875Zm0-1.75a1.263,1.263,0,0,0,.93-.383,1.264,1.264,0,0,0,.383-.93,1.267,1.267,0,0,0-.383-.93,1.267,1.267,0,0,0-.93-.383,1.267,1.267,0,0,0-.93.383,1.268,1.268,0,0,0-.383.93,1.264,1.264,0,0,0,.383.93A1.264,1.264,0,0,0,163.063-795.625ZM170.938-786a2.951,2.951,0,0,1-2.166-.9,2.952,2.952,0,0,1-.9-2.166,2.949,2.949,0,0,1,.9-2.165,2.949,2.949,0,0,1,2.166-.9,2.949,2.949,0,0,1,2.166.9,2.949,2.949,0,0,1,.9,2.165,2.951,2.951,0,0,1-.9,2.166A2.952,2.952,0,0,1,170.938-786Zm0-1.75a1.263,1.263,0,0,0,.93-.383,1.264,1.264,0,0,0,.383-.93,1.267,1.267,0,0,0-.383-.93,1.267,1.267,0,0,0-.93-.383,1.267,1.267,0,0,0-.93.383,1.268,1.268,0,0,0-.383.93,1.264,1.264,0,0,0,.383.93A1.264,1.264,0,0,0,170.938-787.75Zm-10.325,1.139a.832.832,0,0,1-.241-.613.828.828,0,0,1,.241-.612l11.55-11.551a.83.83,0,0,1,.613-.239.829.829,0,0,1,.612.239.833.833,0,0,1,.241.613.83.83,0,0,1-.241.612l-11.55,11.551a.834.834,0,0,1-.613.239A.832.832,0,0,1,160.613-786.611Z"
                        transform="translate(-157 803)" />
                </g>
            </g>
        </svg>
        {{'offers.Percentege discount' | translate }} <span>{{OfferDetailes?.type?.amount}} % </span>
    </div>

    }
    @case ('fixed_amount') {
    <div class="offer_type">

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="icon" transform="translate(-138 -13)">
                <g id="amount_discount_ic">
                    <rect id="container" width="20" height="20" transform="translate(138 13)" opacity="0" />
                    <path id="amount_discount_ic-2" data-name="amount_discount_ic"
                        d="M41.273-790a1.237,1.237,0,0,1-.9-.367A1.193,1.193,0,0,1,40-791.25v-6.25a.6.6,0,0,1,.183-.445.619.619,0,0,1,.453-.18.619.619,0,0,1,.453.18.6.6,0,0,1,.183.445v6.25H51.455a.619.619,0,0,1,.453.18.6.6,0,0,1,.183.445.6.6,0,0,1-.183.445.62.62,0,0,1-.453.18Zm2.545-2.5a1.237,1.237,0,0,1-.9-.367,1.193,1.193,0,0,1-.374-.883v-5a1.194,1.194,0,0,1,.374-.883,1.237,1.237,0,0,1,.9-.367h8.909a1.237,1.237,0,0,1,.9.367,1.194,1.194,0,0,1,.374.883v5a1.193,1.193,0,0,1-.374.883,1.237,1.237,0,0,1-.9.367Zm1.273-1.25a1.194,1.194,0,0,0-.374-.883,1.237,1.237,0,0,0-.9-.367v1.25Zm6.364,0h1.273V-795a1.237,1.237,0,0,0-.9.367A1.194,1.194,0,0,0,51.455-793.75Zm-3.182-.625a1.856,1.856,0,0,0,1.352-.547,1.79,1.79,0,0,0,.557-1.328,1.794,1.794,0,0,0-.557-1.328,1.859,1.859,0,0,0-1.352-.547,1.859,1.859,0,0,0-1.352.547,1.794,1.794,0,0,0-.557,1.328,1.79,1.79,0,0,0,.557,1.328A1.856,1.856,0,0,0,48.273-794.375ZM43.818-797.5a1.237,1.237,0,0,0,.9-.367,1.193,1.193,0,0,0,.374-.883H43.818Zm8.909,0v-1.25H51.455a1.193,1.193,0,0,0,.374.883A1.237,1.237,0,0,0,52.727-797.5Z"
                        transform="translate(101 818)" />
                </g>
            </g>
        </svg>
        {{'offers.Amount discount' | translate }}
        <span>{{OfferDetailes?.type?.amount}} </span> {{"offers.Ryal" | translate}}
    </div>
    }
    @case ('cashback') {
    <div class="offer_type">

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="icon" transform="translate(-138 -13)">
                <g id="cashback_ic">
                    <rect id="container" width="20" height="20" transform="translate(138 13)" opacity="0" />
                    <path id="cashback_ic-2" data-name="cashback_ic"
                        d="M11549.392,10234.636a1.155,1.155,0,0,1-.392-.877v-4.341a1.154,1.154,0,0,1,.392-.875,1.4,1.4,0,0,1,1.884,0,1.157,1.157,0,0,1,.391.875v4.341a1.158,1.158,0,0,1-.391.877,1.4,1.4,0,0,1-1.884,0Zm7.859.256-4.252-1.116v-5.6h1.066a1.044,1.044,0,0,1,.234.024c.076.015.155.033.232.053l4.617,1.6a.737.737,0,0,1,.376.278.727.727,0,0,1,.141.419.657.657,0,0,1-.241.527.851.851,0,0,1-.559.2h-1.75a.688.688,0,0,1-.125-.007.521.521,0,0,1-.109-.039l-.75-.265a.357.357,0,0,0-.267.017.283.283,0,0,0-.166.17.355.355,0,0,0,.016.234.307.307,0,0,0,.184.155l.983.325a.481.481,0,0,0,.1.023,1.037,1.037,0,0,0,.118.006h4.565a1.358,1.358,0,0,1,.933.357,1.139,1.139,0,0,1,.4.882l-4.917,1.722a1.229,1.229,0,0,1-.408.07h-.07A1.528,1.528,0,0,1,11557.251,10234.892Zm2.381-5.672a.522.522,0,0,0-.077-.03l-.793-.274v-.64a.892.892,0,0,0-.622.24.976.976,0,0,0-.123.142l-1.291-.447a1.127,1.127,0,0,0,.277-.754,1.142,1.142,0,0,0-.388-.869,1.388,1.388,0,0,0-1.868,0,1.135,1.135,0,0,0-.386.8l-.018-.006c-.118-.029-.193-.047-.263-.06a1.33,1.33,0,0,0-.264-.028h-2.1v-1.479a.757.757,0,0,1,.258-.578.889.889,0,0,1,.622-.24h6.161a.894.894,0,0,1,.622.24.768.768,0,0,1,.258.578v3.274a1.18,1.18,0,0,1-.007.126h0Zm-1.492-2.821a.891.891,0,0,0,.622.237v-.817h-.88A.768.768,0,0,0,11558.14,10226.4Zm-5.539.237a.891.891,0,0,0,.622-.237.767.767,0,0,0,.257-.58h-.879Z"
                        transform="translate(-11408 -10207)" />
                </g>
            </g>
        </svg>

        {{ 'offers.Cashback' | translate }}
    </div>
    }
    @case ('gift') {
    <div class="offer_type">

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="icon" transform="translate(-138)">
                <g id="get_a_gift_ic" transform="translate(138)">
                    <rect id="container" width="20" height="20" opacity="0" />
                    <path id="get_a_gift_ic-2" data-name="get_a_gift_ic"
                        d="M81.4-907.333v-6a1.382,1.382,0,0,1-.989-.392,1.254,1.254,0,0,1-.411-.942V-916a1.254,1.254,0,0,1,.411-.942,1.382,1.382,0,0,1,.989-.392h2.24a.9.9,0,0,1-.114-.317A2.255,2.255,0,0,1,83.5-918a1.883,1.883,0,0,1,.613-1.417A2.077,2.077,0,0,1,85.6-920a1.988,1.988,0,0,1,.753.142,2.4,2.4,0,0,1,.647.392,2.072,2.072,0,0,1,.647-.4A2.1,2.1,0,0,1,88.4-920a2.077,2.077,0,0,1,1.487.583A1.883,1.883,0,0,1,90.5-918a1.583,1.583,0,0,1-.035.342,1.919,1.919,0,0,1-.1.325H92.6a1.382,1.382,0,0,1,.989.392A1.254,1.254,0,0,1,94-916v1.333a1.254,1.254,0,0,1-.411.942,1.382,1.382,0,0,1-.989.392v6a1.254,1.254,0,0,1-.411.942A1.383,1.383,0,0,1,91.2-906H82.8a1.383,1.383,0,0,1-.989-.392A1.254,1.254,0,0,1,81.4-907.333Zm7-11.333a.7.7,0,0,0-.5.192.63.63,0,0,0-.2.475.63.63,0,0,0,.2.475.7.7,0,0,0,.5.192.7.7,0,0,0,.5-.192.63.63,0,0,0,.2-.475.63.63,0,0,0-.2-.475A.7.7,0,0,0,88.4-918.667ZM84.9-918a.63.63,0,0,0,.2.475.7.7,0,0,0,.5.192.7.7,0,0,0,.5-.192.63.63,0,0,0,.2-.475.63.63,0,0,0-.2-.475.7.7,0,0,0-.5-.192.7.7,0,0,0-.5.192A.63.63,0,0,0,84.9-918Zm-3.5,2v1.333h4.9V-916Zm4.9,8.667v-6H82.8v6Zm1.4,0h3.5v-6H87.7Zm4.9-7.333V-916H87.7v1.333Z"
                        transform="translate(-77 923)" />
                </g>
            </g>
        </svg>
        {{"offers.Free Gift"|translate}}
    </div>
    }
    }

    <div class="offer_status">

        <div class="expired status-badge red" *ngIf="OfferDetailes?.offer_end && !OfferDetailes?.offer_deleted">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <path id="error_FILL0_wght400_GRAD0_opsz24"
                    d="M86-871a.581.581,0,0,0,.427-.173.581.581,0,0,0,.173-.427.58.58,0,0,0-.173-.427A.58.58,0,0,0,86-872.2a.58.58,0,0,0-.427.173.58.58,0,0,0-.173.427.581.581,0,0,0,.173.427A.581.581,0,0,0,86-871Zm0-2.4a.581.581,0,0,0,.427-.172A.581.581,0,0,0,86.6-874v-2.4a.581.581,0,0,0-.173-.427A.581.581,0,0,0,86-877a.581.581,0,0,0-.427.172.581.581,0,0,0-.173.427v2.4a.581.581,0,0,0,.173.427A.581.581,0,0,0,86-873.4Zm0,5.4a5.842,5.842,0,0,1-2.34-.473,6.058,6.058,0,0,1-1.9-1.282,6.059,6.059,0,0,1-1.283-1.905A5.842,5.842,0,0,1,80-874a5.842,5.842,0,0,1,.473-2.34,6.058,6.058,0,0,1,1.283-1.905,6.057,6.057,0,0,1,1.9-1.282A5.841,5.841,0,0,1,86-880a5.841,5.841,0,0,1,2.34.473,6.057,6.057,0,0,1,1.9,1.282,6.058,6.058,0,0,1,1.283,1.905A5.842,5.842,0,0,1,92-874a5.843,5.843,0,0,1-.472,2.34,6.059,6.059,0,0,1-1.283,1.905,6.058,6.058,0,0,1-1.9,1.282A5.843,5.843,0,0,1,86-868Zm0-1.2a4.633,4.633,0,0,0,3.4-1.4,4.633,4.633,0,0,0,1.4-3.4,4.633,4.633,0,0,0-1.4-3.405,4.633,4.633,0,0,0-3.4-1.4,4.633,4.633,0,0,0-3.4,1.4A4.633,4.633,0,0,0,81.2-874a4.633,4.633,0,0,0,1.4,3.4A4.633,4.633,0,0,0,86-869.2ZM86-874Z"
                    transform="translate(-80 880)" fill="#e9580e" />
            </svg>

            {{"offer.Expired offer"|translate}}
        </div>
        <div class="expired status-badge red" *ngIf="OfferDetailes?.offer_deleted">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 18">
                <path id="Path_19036" data-name="Path 19036"
                    d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,9ZM18,4H15.5l-.71-.71a1.009,1.009,0,0,0-.7-.29H9.91a1.009,1.009,0,0,0-.7.29L8.5,4H6A1,1,0,0,0,6,6H18a1,1,0,0,0,0-2Z"
                    transform="translate(-5 -3)" fill="#e9580e" />
            </svg>


            {{"offer.Deleted"|translate}}
        </div>
        <span class="favorite" *ngIf="ShowFavorite">
            <svg class="favorite_icon_offer" id="favourited" xmlns="http://www.w3.org/2000/svg" width="30"
                viewBox="0 0 30 30">
                <circle id="circle" cx="15" cy="15" r="15" fill="#fff" opacity="0.85" />
                <g id="ic_favourite_filled" transform="translate(3 3)">
                    <rect id="container" width="24" height="24" fill="none" />
                    <path id="heart"
                        d="M18.347,4.816A5.654,5.654,0,0,0,11,4.239,5.651,5.651,0,0,0,3.655,12.79L9.246,18.4a2.5,2.5,0,0,0,3.511,0l5.59-5.611a5.664,5.664,0,0,0,0-7.974Z"
                        transform="translate(1.012 0.879)" fill="#dc3545" />
                </g>
            </svg>

        </span>

        <div class="rate" *ngIf="!ShowDistance">
            {{OfferDetailes?.rate ? OfferDetailes?.rate : "0.0"}}
            <ng-container *ngIf="OfferDetailes?.rate; else dimmedStar">
                <img src="/assets/images/icons/star.svg" alt="star">
            </ng-container>
            <ng-template #dimmedStar>
                <img src="/assets/images/icons/star-gray.svg" alt="">
            </ng-template>
        </div>

    </div>
    <div class="with_distance" *ngIf="ShowDistance && OfferDetailes!.distance">
        <div class="rate">
            {{OfferDetailes?.rate ? OfferDetailes?.rate : "0.0"}}
            <ng-container *ngIf="OfferDetailes?.rate; else dimmedStar">
                <img src="/assets/images/icons/star.svg" alt="star">
            </ng-container>
            <ng-template #dimmedStar>
                <img src="/assets/images/icons/star-gray.svg" alt="">
            </ng-template>
        </div>
        <div class="distance">
            <div class="text">{{"offers.Away from you"|translate}}</div>
            <div class="value">
                {{OfferDetailes!.distance|metertToKilometer}}
            </div>
        </div>


    </div>

    <div class="content">
        <div class="title" [class.linkable]="!OfferDetailes?.offer_deleted" (click)="OnOpenVendor($event)">
            <div class="vendor_img">
                <img [src]="OfferDetailes?.vendor?.logo"
                    onerror="this.src = './assets/images/defaults/vendors_default.svg'"
                    (error)="OfferDetailes!.vendor.logo = ''"
                    [ngStyle]="{'object-fit':OfferDetailes?.vendor?.logo ? '' :'scale-down' }" alt="Vendor Logo">
                <!-- <span *ngIf="!OfferDetailes?.vendor?.logo">{{OfferDetailes!.vendor.name | shortName}}</span> -->
            </div>
            <h4>
                {{OfferDetailes?.vendor?.name}}
                @switch (OfferDetailes?.for_all_branches) {
                @case (false) {
                <p>({{OfferDetailes?.number_of_branches}}
                    {{"home.branches"|translate}})</p>
                }
                @case (true) {
                <p>({{"home.All Branches"|translate}})</p>
                }
                }

            </h4>
        </div>
        <div class="desc">
            {{OfferDetailes?.name}}
        </div>
    </div>
</div>