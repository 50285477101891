<div class="page_header">
    <div class="title">
        {{ "offer.offers and discounts" | translate }}
    </div>
    <div class="vendor" *ngIf="VendorDetails">
        <div class="logo">
            <img [src]="VendorDetails.logo" onerror="this.src = './assets/images/defaults/vendors_default.svg'"
                (error)="VendorDetails.logo = ''" [ngStyle]="{'object-fit':VendorDetails?.logo ? '' :'scale-down' }"
                alt="Vendor Logo">
        </div>
        <div class="title">
            {{VendorDetails.name}}
        </div>
    </div>
</div>


<div class="Uper_page">
    <div class="page">
        <div class="page-filters">
            <div class="sort-item">
                <span class="count-page">
                    <span>{{total}}</span> {{ "offer.offers and discounts" | translate }}
                </span>

                <span class="sort-selection">
                    <label for="">{{ 'general.Sorted by' | translate }}:</label>
                    <select class="form-control" [(ngModel)]="filterModel.sort"
                        (ngModelChange)="OnSelectSortType($event)">
                        <option *ngFor="let sortItem of sortItems" [value]="sortItem.value" id="option_row">
                            {{sortItem.name|titlecase}}</option>
                    </select>
                </span>
            </div>

            <div class="filters-container">
                <div class="search-input">
                    <input *ngIf="showSearch" autocomplete="off" autofocus maxlength="20"
                        [(ngModel)]="filterModel.search" (keydown.enter)="makeSearch()">
                    <button mat-icon-button *ngIf="!showSearch" (click)="makeSearch()">
                        <img src="./assets/images/icons/search.svg">
                    </button>
                    <button *ngIf="showSearch" mat-icon-button (click)="resetSearch(); showSearch = false;">
                        <img class="closesvg" src="./assets/images/icons/ic_close.svg">
                    </button>
                </div>

                <button (click)="showFilter = !showFilter" [ngClass]='{"active": showFilter}'
                    class="btn dropdown-custom">
                    <img src="./assets/images/icons/filter.svg">
                    <span>{{"general.Filter" | translate}}</span>
                    <svg width="8" height="5.001" viewBox="0 0 8 5.001">
                        <path id="menu-icon" d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
                            transform="translate(4.5 9.316)" fill="#6a6a6a" opacity="0.8" />
                    </svg>
                </button>
            </div>

            <div class="filter-section" [hidden]="!showFilter">
                <h4 class="title">{{"general.Filter by" | translate}}</h4>
                <div class="row">
                    <div class="col-lg-4 mb-3">
                        <div class="ng-select-field ">
                            <form [formGroup]="filterFormGroup">
                                <ng-select ngSelectMat appearance="fill" formControlName="type" [items]="OfferTypes"
                                    [virtualScroll]="true" bindLabel="name" bindValue="value"
                                    [placeholder]='"offer.Offer Type" | translate'
                                    [notFoundText]='"general.No Data Found" | translate'
                                    [loadingText]='"forms.Loading..." | translate' (clear)="clearFilter()"
                                    dropdownPosition="bottom" [clearable]="true" [multiple]="false"
                                    [searchable]="false">
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        {{item.name}}
                                    </ng-template>
                                </ng-select>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <button mat-stroked-button class="btn-primary" (click)="makeFilter()">{{"general.Apply Filter" |
                        translate}}</button>
                    <button mat-stroked-button (click)="clearFilter()">{{"general.Clear Filter" | translate}}</button>
                </div>
            </div>
        </div>

    </div>


    <div class="offersList row" *ngIf="!dataLoading">
        <div class="col-xl-3 col-md-4 col-sm-6 col-12" *ngFor="let item of OffersList">
            <app-offer-card [OfferDetailes]="item" (OpenOffer)="SelectedOffer=$event"
                (OpenVendor)="SelectedVendor=$event"></app-offer-card>
        </div>
    </div>

    <div *ngIf='dataLoading' class="dataLoading py-5">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <div class="div">
        <ng-container *ngIf="!OffersList?.length && !dataLoading">
            <empty-state [title]='"general.No Data Found" | translate'></empty-state>
        </ng-container>
    </div>


    <div class="pagination-container" *ngIf="total > 12 && !dataLoading">
        <mat-paginator class=" w-100" [length]="total" [pageSize]="filterModel.perPage" [pageSizeOptions]="[12,24 ,48]"
            [pageIndex]="filterModel.page" (page)="handlePageEvent($event)"></mat-paginator>
    </div>
</div>


<app-offer-details (close)="SelectedOffer=''" [selectedId]="SelectedOffer"></app-offer-details>
<app-vendor-details (close)="SelectedVendor=''" [selectedId]="SelectedVendor"></app-vendor-details>