import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../helpers/material-modules';
import { SanitizeHtmlPipePipe } from '../helpers/sanitize-html-pipe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShortNamePipe } from '../helpers/short-name.pipe';
import { InView } from '../helpers/in-view.directive';
import { DirtyCheckGuard } from '../helpers/CanDeactivate/dirty-check.guard';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from '../helpers/CustomPaginatorConfiguration';
import { environment } from '../../environments/environment';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownDirective } from '../helpers/drop-down.directive';
import { LoadingDirective } from '../helpers/loading.directive';
import { OnlynumberDirective } from '../helpers/onlynumber.directive';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FilesViewerComponent } from './files-viewer/files-viewer.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { GoogleMapsModule } from '@angular/google-maps';
import { CdkTreeModule } from '@angular/cdk/tree';
import { SliceNamePipe } from '../helpers/slice-fullname.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OfferCardComponent } from './offer-card/offer-card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OfferDetailsComponent } from './offer-card/offer-details/offer-details.component';
import { OfferQRCodeComponent } from './offer-card/offer-details/offer-qr-code/offer-qr-code.component';
import { OfferBranchesComponent } from './offer-card/offer-details/offer-branches/offer-branches.component';
import { QrCodeModule } from 'ng-qrcode';
import { RedeemOfferComponent } from './offer-card/offer-details/redeem-offer/redeem-offer.component';
import { MetertToKilometerPipe } from '../helpers/metert-to-kilometer.pipe';
import { ImageControlComponent } from './image-control/image-control.component';
import { CropperDialogComponent } from './image-control/cropper-dialog/cropper-dialog.component';
import { TreeSelectionWithImgComponent } from './tree-selection-with-img/tree-selection-with-img.component';
import { VendorBranchesComponent } from './vendor-details/vendor-branches/vendor-branches.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { VendorOffersComponent } from './vendor-details/vendor-offers/vendor-offers.component';
import { CountDownComponent } from './count-down/count-down.component';
import { DateAgoPipe } from '../helpers/date-ago.pipe';
import { VendorRegisterComponent } from './vendor-register/vendor-register.component';
import { CompanyRegisterComponent } from './company-register/company-register.component';
import { SearchGoogleMapsComponent } from './search-google-maps/search-google-maps.component';
import { NgxStarsModule } from 'ngx-stars';

registerLocaleData(localeAr);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    // Directives
    InView,
    LoadingDirective,
    // Pipes
    SanitizeHtmlPipePipe,
    ShortNamePipe,
    DropDownDirective,
    OnlynumberDirective,
    SliceNamePipe,
    MetertToKilometerPipe,
    // Components
    FileManagerComponent,
    FilesViewerComponent,
    EmptyStateComponent,
    OfferCardComponent,
    OfferDetailsComponent,
    OfferQRCodeComponent,
    OfferBranchesComponent,
    RedeemOfferComponent,
    ImageControlComponent,
    CropperDialogComponent,
    TreeSelectionWithImgComponent,
    VendorDetailsComponent,
    VendorBranchesComponent,
    VendorOffersComponent,
    CountDownComponent,
    DateAgoPipe,
    CompanyRegisterComponent,
    VendorRegisterComponent,
    SearchGoogleMapsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild({}),
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    RecaptchaModule,
    GoogleMapsModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgbModule,
    CdkTreeModule,
    ImageCropperModule,
    CarouselModule,
    InfiniteScrollModule,
    QrCodeModule,
    NgxStarsModule
  ],
  exports: [
    MaterialModule,
    SanitizeHtmlPipePipe,
    ShortNamePipe,
    SliceNamePipe,
    MetertToKilometerPipe,
    InView,
    NgSelectModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    CdkTreeModule,
    FormsModule,
    TranslateModule,
    RecaptchaModule,
    LottieModule,
    NgbModule,
    DropDownDirective,
    LoadingDirective,
    OnlynumberDirective,
    FileManagerComponent,
    FilesViewerComponent,
    EmptyStateComponent,
    CarouselModule,
    OfferCardComponent,
    ImageControlComponent,
    CropperDialogComponent,
    InfiniteScrollModule,
    TreeSelectionWithImgComponent,
    VendorDetailsComponent,
    VendorBranchesComponent,
    VendorOffersComponent,
    QrCodeModule,
    CountDownComponent,
    DateAgoPipe,
    OfferDetailsComponent,
    CompanyRegisterComponent,
    VendorRegisterComponent,
    SearchGoogleMapsComponent

  ],
  providers: [
    DirtyCheckGuard,
    DatePipe,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'ar' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleSiteKey,
        useValue: localStorage.getItem('language') || 'ar'
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleSiteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
  ]
})
export class SharedModule { }
