<div class="dialog">
    <div class="dialog_header">
        <!-- data?.rate?true:false -->
        <a (click)="dialogRef.close()"><svg xmlns="http://www.w3.org/2000/svg" width="18"
                height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </a>
        {{"offer.Offer apply details"|translate}}
    </div>
    <div class="dialog_content p-0" mat-dialog-content>
        @if (dataLoading) {
        <div class="dataLoading">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        }
        @else {
            
        @switch (data?.status) {
        @case ('redeemed') {
        <div class="offer_qrCode">
            <div class="label">{{"offer.Tell the employee this code"|translate}}</div>
            <div class="offer_code">{{data?.code}}</div>
            <div class="label">{{"offer.Or show the QR code to the vendor employee to claim the offer"|translate}}</div>
            <div class="offer_QR_image">
                <qr-code darkColor="#1C1E44" [margin]="0" [value]="data?.code" size="160"
                    errorCorrectionLevel="M"></qr-code>

                <!-- <img src="/assets/images/covers/qr-code.png" alt="QR Code" class="qr_code"> -->
            </div>
            <div class="status">
                <div class="item date">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="ic_redeemed_points"
                            d="M21057.506,16191.554a1.385,1.385,0,0,1-.5-1.072v-5.311a1.378,1.378,0,0,1,.5-1.067,1.872,1.872,0,0,1,2.424,0,1.372,1.372,0,0,1,.5,1.067v5.311a1.379,1.379,0,0,1-.5,1.072,1.875,1.875,0,0,1-2.424,0Zm10.105.312-5.467-1.365v-6.842h1.371a1.737,1.737,0,0,1,.3.025c.1.022.2.042.3.067l5.938,1.956a.917.917,0,0,1,.479.338.845.845,0,0,1,.189.512.784.784,0,0,1-.316.646,1.124,1.124,0,0,1-.713.246h-2.254a.914.914,0,0,1-.16-.011.651.651,0,0,1-.139-.048l-.963-.322a.477.477,0,0,0-.348.023.341.341,0,0,0-.211.206.344.344,0,0,0,.025.282.354.354,0,0,0,.23.191l1.266.4a.444.444,0,0,0,.127.033c.059,0,.105.007.154.007h5.869a1.794,1.794,0,0,1,1.2.437,1.354,1.354,0,0,1,.512,1.082l-6.32,2.105a1.752,1.752,0,0,1-.523.082h-.076A1.9,1.9,0,0,1,21067.611,16191.865Zm-.117-8.247a5.469,5.469,0,0,1-1.711-1.068,5.018,5.018,0,0,1-1.152-1.592,4.664,4.664,0,0,1,0-3.9,5.048,5.048,0,0,1,1.152-1.594,5.536,5.536,0,0,1,1.711-1.066,5.791,5.791,0,0,1,4.217,0,5.545,5.545,0,0,1,1.713,1.066,5.127,5.127,0,0,1,1.158,1.594,4.723,4.723,0,0,1,0,3.9,5.1,5.1,0,0,1-1.158,1.592,5.478,5.478,0,0,1-1.713,1.068,5.791,5.791,0,0,1-4.217,0Zm3.605-2.086a.3.3,0,0,0,.33.01.2.2,0,0,0,.092-.269l-.568-1.74,1.471-.978a.215.215,0,0,0,.1-.278.25.25,0,0,0-.258-.174h-1.795l-.6-1.828a.265.265,0,0,0-.262-.17.242.242,0,0,0-.252.17l-.613,1.828h-1.793a.258.258,0,0,0-.26.174.213.213,0,0,0,.1.278l1.469.978-.564,1.74a.2.2,0,0,0,.1.269.291.291,0,0,0,.322-.01l1.5-1.05Z"
                            transform="translate(-21057.004 -16173.998)" fill="#999aab" />
                    </svg>
                    {{"offer.Date of redeemed offer"|translate}} :
                    <span>{{data?.date | date:'d MMMM yyyy - h:mm a'}}</span>
                </div>

                <div class="status-badge yellow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="not-claimed-yet-ic"
                            d="M84.5-869.65a1.3,1.3,0,0,0,.956-.394A1.3,1.3,0,0,0,85.85-871a1.3,1.3,0,0,0-.394-.956,1.3,1.3,0,0,0-.956-.394,1.3,1.3,0,0,0-.956.394,1.3,1.3,0,0,0-.394.956,1.3,1.3,0,0,0,.394.956A1.3,1.3,0,0,0,84.5-869.65Zm4.5,0a1.3,1.3,0,0,0,.956-.394A1.3,1.3,0,0,0,90.35-871a1.3,1.3,0,0,0-.394-.956A1.3,1.3,0,0,0,89-872.35a1.3,1.3,0,0,0-.956.394,1.3,1.3,0,0,0-.394.956,1.3,1.3,0,0,0,.394.956A1.3,1.3,0,0,0,89-869.65Zm4.5,0a1.3,1.3,0,0,0,.956-.394A1.3,1.3,0,0,0,94.85-871a1.3,1.3,0,0,0-.394-.956,1.3,1.3,0,0,0-.956-.394,1.3,1.3,0,0,0-.956.394,1.3,1.3,0,0,0-.394.956,1.3,1.3,0,0,0,.394.956A1.3,1.3,0,0,0,93.5-869.65ZM89-862a8.764,8.764,0,0,1-3.51-.709,9.089,9.089,0,0,1-2.857-1.924,9.089,9.089,0,0,1-1.924-2.857A8.764,8.764,0,0,1,80-871a8.764,8.764,0,0,1,.709-3.51,9.089,9.089,0,0,1,1.924-2.858,9.089,9.089,0,0,1,2.857-1.924A8.763,8.763,0,0,1,89-880a8.763,8.763,0,0,1,3.51.709,9.089,9.089,0,0,1,2.858,1.924,9.089,9.089,0,0,1,1.924,2.858A8.764,8.764,0,0,1,98-871a8.764,8.764,0,0,1-.709,3.51,9.089,9.089,0,0,1-1.924,2.857,9.089,9.089,0,0,1-2.858,1.924A8.764,8.764,0,0,1,89-862Zm0-1.8a6.949,6.949,0,0,0,5.107-2.092A6.949,6.949,0,0,0,96.2-871a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,89-878.2a6.949,6.949,0,0,0-5.107,2.092A6.949,6.949,0,0,0,81.8-871a6.949,6.949,0,0,0,2.093,5.107A6.949,6.949,0,0,0,89-863.8ZM89-871Z"
                            transform="translate(-80 880)" fill="#f2cb00" />
                    </svg>
                    {{"offer.Not yet claimed"|translate}}
                </div>

            </div>
        </div>
        }
        @case ('claimed') {

        <div class="offer_qrCode">
            <div class="label">{{"offer.Offer code"|translate}}</div>
            <div class="offer_code">{{data?.code}}</div>
            <div class="status">
                <div class="item date">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="ic_redeemed_points"
                            d="M21057.506,16191.554a1.385,1.385,0,0,1-.5-1.072v-5.311a1.378,1.378,0,0,1,.5-1.067,1.872,1.872,0,0,1,2.424,0,1.372,1.372,0,0,1,.5,1.067v5.311a1.379,1.379,0,0,1-.5,1.072,1.875,1.875,0,0,1-2.424,0Zm10.105.312-5.467-1.365v-6.842h1.371a1.737,1.737,0,0,1,.3.025c.1.022.2.042.3.067l5.938,1.956a.917.917,0,0,1,.479.338.845.845,0,0,1,.189.512.784.784,0,0,1-.316.646,1.124,1.124,0,0,1-.713.246h-2.254a.914.914,0,0,1-.16-.011.651.651,0,0,1-.139-.048l-.963-.322a.477.477,0,0,0-.348.023.341.341,0,0,0-.211.206.344.344,0,0,0,.025.282.354.354,0,0,0,.23.191l1.266.4a.444.444,0,0,0,.127.033c.059,0,.105.007.154.007h5.869a1.794,1.794,0,0,1,1.2.437,1.354,1.354,0,0,1,.512,1.082l-6.32,2.105a1.752,1.752,0,0,1-.523.082h-.076A1.9,1.9,0,0,1,21067.611,16191.865Zm-.117-8.247a5.469,5.469,0,0,1-1.711-1.068,5.018,5.018,0,0,1-1.152-1.592,4.664,4.664,0,0,1,0-3.9,5.048,5.048,0,0,1,1.152-1.594,5.536,5.536,0,0,1,1.711-1.066,5.791,5.791,0,0,1,4.217,0,5.545,5.545,0,0,1,1.713,1.066,5.127,5.127,0,0,1,1.158,1.594,4.723,4.723,0,0,1,0,3.9,5.1,5.1,0,0,1-1.158,1.592,5.478,5.478,0,0,1-1.713,1.068,5.791,5.791,0,0,1-4.217,0Zm3.605-2.086a.3.3,0,0,0,.33.01.2.2,0,0,0,.092-.269l-.568-1.74,1.471-.978a.215.215,0,0,0,.1-.278.25.25,0,0,0-.258-.174h-1.795l-.6-1.828a.265.265,0,0,0-.262-.17.242.242,0,0,0-.252.17l-.613,1.828h-1.793a.258.258,0,0,0-.26.174.213.213,0,0,0,.1.278l1.469.978-.564,1.74a.2.2,0,0,0,.1.269.291.291,0,0,0,.322-.01l1.5-1.05Z"
                            transform="translate(-21057.004 -16173.998)" fill="#999aab" />
                    </svg>
                    {{"offer.Date of redeemed offer"|translate}} :
                    <span>{{data?.date | date:'d MMMM yyyy - h:mm a'}}</span>
                </div>

                <div class="status-badge green mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="claimed-ic"
                            d="M87.74-869.38l-1.935-1.935a.853.853,0,0,0-.63-.247.853.853,0,0,0-.63.247.854.854,0,0,0-.248.63.853.853,0,0,0,.248.63l2.565,2.565a.864.864,0,0,0,.63.27.863.863,0,0,0,.63-.27l5.085-5.085a.853.853,0,0,0,.248-.63.853.853,0,0,0-.248-.63.853.853,0,0,0-.63-.248.853.853,0,0,0-.63.248ZM89-862a8.764,8.764,0,0,1-3.51-.709,9.089,9.089,0,0,1-2.858-1.924,9.088,9.088,0,0,1-1.924-2.857A8.764,8.764,0,0,1,80-871a8.764,8.764,0,0,1,.709-3.51,9.089,9.089,0,0,1,1.924-2.858,9.089,9.089,0,0,1,2.858-1.924A8.763,8.763,0,0,1,89-880a8.763,8.763,0,0,1,3.51.709,9.089,9.089,0,0,1,2.858,1.924,9.089,9.089,0,0,1,1.924,2.858A8.764,8.764,0,0,1,98-871a8.764,8.764,0,0,1-.709,3.51,9.088,9.088,0,0,1-1.924,2.857,9.089,9.089,0,0,1-2.858,1.924A8.764,8.764,0,0,1,89-862Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,96.2-871a6.949,6.949,0,0,0-2.092-5.108A6.949,6.949,0,0,0,89-878.2a6.949,6.949,0,0,0-5.108,2.092A6.949,6.949,0,0,0,81.8-871a6.949,6.949,0,0,0,2.092,5.107A6.949,6.949,0,0,0,89-863.8ZM89-871Z"
                            transform="translate(-80 880)" fill="#43a047" />
                    </svg>

                    {{"offer.Applied in vendor"|translate}}
                </div>

                <div class="item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="ic_redeemed_points"
                            d="M21057.506,16191.554a1.385,1.385,0,0,1-.5-1.072v-5.311a1.378,1.378,0,0,1,.5-1.067,1.872,1.872,0,0,1,2.424,0,1.372,1.372,0,0,1,.5,1.067v5.311a1.379,1.379,0,0,1-.5,1.072,1.875,1.875,0,0,1-2.424,0Zm10.105.312-5.467-1.365v-6.842h1.371a1.737,1.737,0,0,1,.3.025c.1.022.2.042.3.067l5.938,1.956a.917.917,0,0,1,.479.338.845.845,0,0,1,.189.512.784.784,0,0,1-.316.646,1.124,1.124,0,0,1-.713.246h-2.254a.914.914,0,0,1-.16-.011.651.651,0,0,1-.139-.048l-.963-.322a.477.477,0,0,0-.348.023.341.341,0,0,0-.211.206.344.344,0,0,0,.025.282.354.354,0,0,0,.23.191l1.266.4a.444.444,0,0,0,.127.033c.059,0,.105.007.154.007h5.869a1.794,1.794,0,0,1,1.2.437,1.354,1.354,0,0,1,.512,1.082l-6.32,2.105a1.752,1.752,0,0,1-.523.082h-.076A1.9,1.9,0,0,1,21067.611,16191.865Zm-.117-8.247a5.469,5.469,0,0,1-1.711-1.068,5.018,5.018,0,0,1-1.152-1.592,4.664,4.664,0,0,1,0-3.9,5.048,5.048,0,0,1,1.152-1.594,5.536,5.536,0,0,1,1.711-1.066,5.791,5.791,0,0,1,4.217,0,5.545,5.545,0,0,1,1.713,1.066,5.127,5.127,0,0,1,1.158,1.594,4.723,4.723,0,0,1,0,3.9,5.1,5.1,0,0,1-1.158,1.592,5.478,5.478,0,0,1-1.713,1.068,5.791,5.791,0,0,1-4.217,0Zm3.605-2.086a.3.3,0,0,0,.33.01.2.2,0,0,0,.092-.269l-.568-1.74,1.471-.978a.215.215,0,0,0,.1-.278.25.25,0,0,0-.258-.174h-1.795l-.6-1.828a.265.265,0,0,0-.262-.17.242.242,0,0,0-.252.17l-.613,1.828h-1.793a.258.258,0,0,0-.26.174.213.213,0,0,0,.1.278l1.469.978-.564,1.74a.2.2,0,0,0,.1.269.291.291,0,0,0,.322-.01l1.5-1.05Z"
                            transform="translate(-21057.004 -16173.998)" fill="#999aab" />
                    </svg>
                    {{"offer.Date of applied offer"|translate}}:
                    <span>{{data?.offersApprovedAt | date:'d MMMM yyyy - h:mm a'}} </span>
                </div>

                <div class="item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17">
                        <path id="ic_hash_tag"
                            d="M136.439-787.25l-.85,3.48a.977.977,0,0,1-.335.558.939.939,0,0,1-.618.213.966.966,0,0,1-.8-.4.956.956,0,0,1-.18-.877l.721-2.975h-2.807a.979.979,0,0,1-.824-.412,1.031,1.031,0,0,1-.18-.916.942.942,0,0,1,.361-.584,1.045,1.045,0,0,1,.644-.213h3.322l1.03-4.25h-2.807a.979.979,0,0,1-.824-.412,1.031,1.031,0,0,1-.18-.916.942.942,0,0,1,.361-.584,1.045,1.045,0,0,1,.644-.213h3.322l.85-3.48a.977.977,0,0,1,.335-.558.94.94,0,0,1,.618-.213.965.965,0,0,1,.8.4.956.956,0,0,1,.18.877l-.721,2.975h4.121l.85-3.48a.977.977,0,0,1,.335-.558.94.94,0,0,1,.618-.213.965.965,0,0,1,.8.4.956.956,0,0,1,.18.877l-.721,2.975h2.807a.979.979,0,0,1,.824.412,1.03,1.03,0,0,1,.18.916.942.942,0,0,1-.361.584,1.046,1.046,0,0,1-.644.212h-3.322l-1.03,4.25h2.807a.979.979,0,0,1,.824.412,1.031,1.031,0,0,1,.18.916.943.943,0,0,1-.361.584,1.046,1.046,0,0,1-.644.212H142.62l-.85,3.48a.977.977,0,0,1-.335.558.939.939,0,0,1-.618.213.966.966,0,0,1-.8-.4.956.956,0,0,1-.18-.877l.721-2.975Zm.515-2.125h4.121l1.03-4.25h-4.121Z"
                            transform="translate(-130.529 800)" fill="#999aab" />
                    </svg>

                    {{"offer.Transaction No"|translate}}:
                    <span>{{data?.ref_number}}</span>
                </div>

                <div class="item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17">
                        <path id="ic_branch"
                            d="M115.134-800h13.733a.912.912,0,0,1,.7.306,1.073,1.073,0,0,1,.282.757,1.074,1.074,0,0,1-.282.757.914.914,0,0,1-.7.306H115.134a.913.913,0,0,1-.7-.306,1.074,1.074,0,0,1-.282-.757,1.073,1.073,0,0,1,.282-.757A.912.912,0,0,1,115.134-800Zm0,17a.913.913,0,0,1-.7-.305,1.074,1.074,0,0,1-.282-.757v-5.313h-.172a.915.915,0,0,1-.76-.385,1.066,1.066,0,0,1-.2-.89l.981-5.312a1.07,1.07,0,0,1,.343-.611.9.9,0,0,1,.613-.239h14.076a.9.9,0,0,1,.613.239,1.07,1.07,0,0,1,.343.611l.981,5.312a1.066,1.066,0,0,1-.2.89.915.915,0,0,1-.76.385h-.172v5.313a1.074,1.074,0,0,1-.282.757.913.913,0,0,1-.7.305.913.913,0,0,1-.7-.305,1.074,1.074,0,0,1-.282-.757v-5.313h-3.924v5.313a1.074,1.074,0,0,1-.282.757.913.913,0,0,1-.7.305Zm.981-2.125H122v-4.25h-5.886Z"
                            transform="translate(-113 800)" fill="#999aab" />
                    </svg>

                    {{"offer.The branch where the offer is applied"|translate}}:
                    <span> {{data?.brancheName}}</span>
                </div>
            </div>

            <div class="rating_section">
                <div class="title">{{data.rate ? ("offer.Your rate of the offer"|translate):
                    ("offer.Share with us your satisfaction and rating of this offer"|translate)}}</div>
                <ngx-stars (ratingOutput)="Rate=$event" [readonly]="data?.rate ? true :false"
                    [wholeStars]="data?.rate ? false :true" [initialStars]="data?.rate || 0" [size]="3"
                    [customStarIcons]="{empty:'/assets/images/icons/star_empty.svg',full:'/assets/images/icons/star.svg',half:'/assets/images/icons/star_half.svg'}"
                    [color]="'#F8A02A'"></ngx-stars>
                <button mat-button *ngIf="!data.rate" [class.loading]="submitRateLoader" (click)="OnRateOffer()"
                    [class.disabled]="!Rate || submitRateLoader">{{"offer.Offer Rate"|translate}}</button>
            </div>
        </div>
        }
        }
        }




    </div>

    <div class="dialog_actions justify-content-end" mat-dialog-actions>
        <!-- data?.rate?true:false -->
        <button mat-button class="btn-secondary" (click)="dialogRef.close()">
            {{"general.close"|translate}}</button>
    </div>
</div>