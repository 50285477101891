import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from '../../../../helpers/config.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Offer } from '../../../../features/panel/models/offers';
import { SocketEventType, SocketService } from '../../../../services/socket.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { OffersService } from '../../../../features/panel/services/offers.service';

@Component({
  selector: 'app-offer-qr-code',
  templateUrl: './offer-qr-code.component.html',
  styles: ``
})
export class OfferQRCodeComponent implements OnInit {

  subscribtions: Subscription[] = []
  Rate: any
  submitRateLoader: boolean = false;
  dataLoading: boolean = false;
  OfferDeatils!: Offer
  constructor(private _config: ConfigService,
    public dialogRef: MatDialogRef<OfferQRCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _socket: SocketService,
    private _offerService: OffersService,
  ) {
    console.log(data);

  }

  ngOnInit(): void {

    if (this.data?.Offer_ID) {
      this.GetOfferDetails(this.data.Offer_ID);
    }
    this.subscribtions.push(
      this._socket.getDataStream(SocketEventType.Redeemed_Offers).subscribe((result: any) => {
        if (this.data?.id == result.data.offer_id) {
          this.dialogRef.close();
          // Swal.fire({
          //   title: this._config._translate.instant("offer.The offer has been claimed successfully"),
          //   text: '',
          //   imageUrl: './assets/images/icons/success.svg',
          //   confirmButtonText: this._config._translate.instant('swalMsg.Ok')
          // });
        }
      })
    )
  }


  OnRateOffer() {
    let model = { offer_id: this.data.id, rate: this.Rate }
    this.submitRateLoader = true;
    this._offerService.RateOffer(model).subscribe(
      (res) => {
        this.submitRateLoader = false;
        this.data.rate = this.Rate;
        this._offerService.refreshDetails.next(true);
      },
      (err)=>{
        this.submitRateLoader = false;
      }
    )

  }


  GetOfferDetails(offerID: string) {
    this.dataLoading = true;
    this._offerService.GetOfferDetails(offerID).subscribe({
      next: (res: any) => {
        this.OfferDeatils = res.data;
        this.data = this.OfferDeatils?.used_offer_info;
        this.data.id = offerID;
        this.data.rate = this.OfferDeatils?.my_rate;
        this.dataLoading = false;
        // this.OfferDeatils.offer_end = true;
        // this.OfferDeatils.user_use_offer = false;
      },
      error: (err: any) => {
        this.dataLoading = false;
      }
    })
  }


  ngOnDestroy(): void {
    this.subscribtions.forEach(element => {
      element.unsubscribe();
    });
  }
}
