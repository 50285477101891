<div class="side-sheet" [ngClass]="{'active': selectedId}">
    <div class="d-flex h-100">

        <div class="overlay" (click)="close.emit()"></div>

        <div class="sheet-container">
            <div class="sheet-head">
                <div class="title"> {{"offer.Offer details"|translate}}</div>

                <button type="button" mat-icon-button (click)="close.emit()">
                    <img src="./assets/images/icons/ic_close.svg" alt="">
                </button>
            </div>
            <div *ngIf='dataLoading' class="dataLoading">
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>


            <ng-container *ngIf="!dataLoading">

                <div class="sheet-content p-0">

                    <div class="offerDetails" *ngIf="selectedId">
                        <div class="offer_header">
                            <img class="cover_img" [src]="OfferDeatils?.image"
                                onerror="this.src = './assets/images/defaults/offer_card_default.svg'"
                                alt="Offer Image">
                            <div class="content">
                                <div class="vendor_img">
                                    <img [src]="OfferDeatils?.vendor?.logo"
                                        onerror="this.src = './assets/images/defaults/vendors_default.svg'"
                                        (error)="OfferDeatils!.vendor.logo = ''"
                                        [ngStyle]="{'object-fit':OfferDeatils?.vendor?.logo ? '' :'scale-down' }"
                                        alt="Vendor Logo">


                                </div>
                                <h5>{{OfferDeatils?.vendor?.name}}</h5>
                            </div>
                        </div>

                        <div class="offer_info">
                            @switch (OfferDeatils?.type?.code) {
                            @case ('percentage') {
                            <div class="offer_type">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="icon" transform="translate(-138)">
                                        <g id="ic_percentage_discount" transform="translate(138)">
                                            <rect id="container" width="20" height="20" opacity="0" />
                                            <path id="percentage_discount_ic"
                                                d="M163.063-793.875a2.951,2.951,0,0,1-2.166-.9,2.952,2.952,0,0,1-.9-2.166,2.949,2.949,0,0,1,.9-2.165,2.949,2.949,0,0,1,2.166-.9,2.949,2.949,0,0,1,2.166.9,2.949,2.949,0,0,1,.9,2.165,2.951,2.951,0,0,1-.9,2.166A2.952,2.952,0,0,1,163.063-793.875Zm0-1.75a1.263,1.263,0,0,0,.93-.383,1.264,1.264,0,0,0,.383-.93,1.267,1.267,0,0,0-.383-.93,1.267,1.267,0,0,0-.93-.383,1.267,1.267,0,0,0-.93.383,1.268,1.268,0,0,0-.383.93,1.264,1.264,0,0,0,.383.93A1.264,1.264,0,0,0,163.063-795.625ZM170.938-786a2.951,2.951,0,0,1-2.166-.9,2.952,2.952,0,0,1-.9-2.166,2.949,2.949,0,0,1,.9-2.165,2.949,2.949,0,0,1,2.166-.9,2.949,2.949,0,0,1,2.166.9,2.949,2.949,0,0,1,.9,2.165,2.951,2.951,0,0,1-.9,2.166A2.952,2.952,0,0,1,170.938-786Zm0-1.75a1.263,1.263,0,0,0,.93-.383,1.264,1.264,0,0,0,.383-.93,1.267,1.267,0,0,0-.383-.93,1.267,1.267,0,0,0-.93-.383,1.267,1.267,0,0,0-.93.383,1.268,1.268,0,0,0-.383.93,1.264,1.264,0,0,0,.383.93A1.264,1.264,0,0,0,170.938-787.75Zm-10.325,1.139a.832.832,0,0,1-.241-.613.828.828,0,0,1,.241-.612l11.55-11.551a.83.83,0,0,1,.613-.239.829.829,0,0,1,.612.239.833.833,0,0,1,.241.613.83.83,0,0,1-.241.612l-11.55,11.551a.834.834,0,0,1-.613.239A.832.832,0,0,1,160.613-786.611Z"
                                                transform="translate(-157 803)" />
                                        </g>
                                    </g>
                                </svg>
                                {{'offers.Percentege discount' | translate }} <span> {{OfferDeatils?.type?.amount}}
                                    %</span>
                            </div>

                            }
                            @case ('fixed_amount') {
                            <div class="offer_type">

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="icon" transform="translate(-138 -13)">
                                        <g id="amount_discount_ic">
                                            <rect id="container" width="20" height="20" transform="translate(138 13)"
                                                opacity="0" />
                                            <path id="amount_discount_ic-2" data-name="amount_discount_ic"
                                                d="M41.273-790a1.237,1.237,0,0,1-.9-.367A1.193,1.193,0,0,1,40-791.25v-6.25a.6.6,0,0,1,.183-.445.619.619,0,0,1,.453-.18.619.619,0,0,1,.453.18.6.6,0,0,1,.183.445v6.25H51.455a.619.619,0,0,1,.453.18.6.6,0,0,1,.183.445.6.6,0,0,1-.183.445.62.62,0,0,1-.453.18Zm2.545-2.5a1.237,1.237,0,0,1-.9-.367,1.193,1.193,0,0,1-.374-.883v-5a1.194,1.194,0,0,1,.374-.883,1.237,1.237,0,0,1,.9-.367h8.909a1.237,1.237,0,0,1,.9.367,1.194,1.194,0,0,1,.374.883v5a1.193,1.193,0,0,1-.374.883,1.237,1.237,0,0,1-.9.367Zm1.273-1.25a1.194,1.194,0,0,0-.374-.883,1.237,1.237,0,0,0-.9-.367v1.25Zm6.364,0h1.273V-795a1.237,1.237,0,0,0-.9.367A1.194,1.194,0,0,0,51.455-793.75Zm-3.182-.625a1.856,1.856,0,0,0,1.352-.547,1.79,1.79,0,0,0,.557-1.328,1.794,1.794,0,0,0-.557-1.328,1.859,1.859,0,0,0-1.352-.547,1.859,1.859,0,0,0-1.352.547,1.794,1.794,0,0,0-.557,1.328,1.79,1.79,0,0,0,.557,1.328A1.856,1.856,0,0,0,48.273-794.375ZM43.818-797.5a1.237,1.237,0,0,0,.9-.367,1.193,1.193,0,0,0,.374-.883H43.818Zm8.909,0v-1.25H51.455a1.193,1.193,0,0,0,.374.883A1.237,1.237,0,0,0,52.727-797.5Z"
                                                transform="translate(101 818)" />
                                        </g>
                                    </g>
                                </svg>
                                {{'offers.Amount discount' | translate }}
                                <span> {{OfferDeatils?.type?.amount}} {{"offers.Ryal" | translate}}</span>
                            </div>
                            }
                            @case ('cashback') {
                            <div class="offer_type">

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="icon" transform="translate(-138 -13)">
                                        <g id="cashback_ic">
                                            <rect id="container" width="20" height="20" transform="translate(138 13)"
                                                opacity="0" />
                                            <path id="cashback_ic-2" data-name="cashback_ic"
                                                d="M11549.392,10234.636a1.155,1.155,0,0,1-.392-.877v-4.341a1.154,1.154,0,0,1,.392-.875,1.4,1.4,0,0,1,1.884,0,1.157,1.157,0,0,1,.391.875v4.341a1.158,1.158,0,0,1-.391.877,1.4,1.4,0,0,1-1.884,0Zm7.859.256-4.252-1.116v-5.6h1.066a1.044,1.044,0,0,1,.234.024c.076.015.155.033.232.053l4.617,1.6a.737.737,0,0,1,.376.278.727.727,0,0,1,.141.419.657.657,0,0,1-.241.527.851.851,0,0,1-.559.2h-1.75a.688.688,0,0,1-.125-.007.521.521,0,0,1-.109-.039l-.75-.265a.357.357,0,0,0-.267.017.283.283,0,0,0-.166.17.355.355,0,0,0,.016.234.307.307,0,0,0,.184.155l.983.325a.481.481,0,0,0,.1.023,1.037,1.037,0,0,0,.118.006h4.565a1.358,1.358,0,0,1,.933.357,1.139,1.139,0,0,1,.4.882l-4.917,1.722a1.229,1.229,0,0,1-.408.07h-.07A1.528,1.528,0,0,1,11557.251,10234.892Zm2.381-5.672a.522.522,0,0,0-.077-.03l-.793-.274v-.64a.892.892,0,0,0-.622.24.976.976,0,0,0-.123.142l-1.291-.447a1.127,1.127,0,0,0,.277-.754,1.142,1.142,0,0,0-.388-.869,1.388,1.388,0,0,0-1.868,0,1.135,1.135,0,0,0-.386.8l-.018-.006c-.118-.029-.193-.047-.263-.06a1.33,1.33,0,0,0-.264-.028h-2.1v-1.479a.757.757,0,0,1,.258-.578.889.889,0,0,1,.622-.24h6.161a.894.894,0,0,1,.622.24.768.768,0,0,1,.258.578v3.274a1.18,1.18,0,0,1-.007.126h0Zm-1.492-2.821a.891.891,0,0,0,.622.237v-.817h-.88A.768.768,0,0,0,11558.14,10226.4Zm-5.539.237a.891.891,0,0,0,.622-.237.767.767,0,0,0,.257-.58h-.879Z"
                                                transform="translate(-11408 -10207)" />
                                        </g>
                                    </g>
                                </svg>

                                {{ 'offers.Cashback' | translate }}
                            </div>
                            }
                            @case ('gift') {
                            <div class="offer_type">

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="icon" transform="translate(-138)">
                                        <g id="get_a_gift_ic" transform="translate(138)">
                                            <rect id="container" width="20" height="20" opacity="0" />
                                            <path id="get_a_gift_ic-2" data-name="get_a_gift_ic"
                                                d="M81.4-907.333v-6a1.382,1.382,0,0,1-.989-.392,1.254,1.254,0,0,1-.411-.942V-916a1.254,1.254,0,0,1,.411-.942,1.382,1.382,0,0,1,.989-.392h2.24a.9.9,0,0,1-.114-.317A2.255,2.255,0,0,1,83.5-918a1.883,1.883,0,0,1,.613-1.417A2.077,2.077,0,0,1,85.6-920a1.988,1.988,0,0,1,.753.142,2.4,2.4,0,0,1,.647.392,2.072,2.072,0,0,1,.647-.4A2.1,2.1,0,0,1,88.4-920a2.077,2.077,0,0,1,1.487.583A1.883,1.883,0,0,1,90.5-918a1.583,1.583,0,0,1-.035.342,1.919,1.919,0,0,1-.1.325H92.6a1.382,1.382,0,0,1,.989.392A1.254,1.254,0,0,1,94-916v1.333a1.254,1.254,0,0,1-.411.942,1.382,1.382,0,0,1-.989.392v6a1.254,1.254,0,0,1-.411.942A1.383,1.383,0,0,1,91.2-906H82.8a1.383,1.383,0,0,1-.989-.392A1.254,1.254,0,0,1,81.4-907.333Zm7-11.333a.7.7,0,0,0-.5.192.63.63,0,0,0-.2.475.63.63,0,0,0,.2.475.7.7,0,0,0,.5.192.7.7,0,0,0,.5-.192.63.63,0,0,0,.2-.475.63.63,0,0,0-.2-.475A.7.7,0,0,0,88.4-918.667ZM84.9-918a.63.63,0,0,0,.2.475.7.7,0,0,0,.5.192.7.7,0,0,0,.5-.192.63.63,0,0,0,.2-.475.63.63,0,0,0-.2-.475.7.7,0,0,0-.5-.192.7.7,0,0,0-.5.192A.63.63,0,0,0,84.9-918Zm-3.5,2v1.333h4.9V-916Zm4.9,8.667v-6H82.8v6Zm1.4,0h3.5v-6H87.7Zm4.9-7.333V-916H87.7v1.333Z"
                                                transform="translate(-77 923)" />
                                        </g>
                                    </g>
                                </svg>
                                {{"offers.Free Gift"|translate}}
                            </div>
                            }
                            }

                            <div class="title">
                                {{OfferDeatils?.name}}
                            </div>
                            <div class="hint">
                                {{"offer.Offers are valid Terms and conditions apply"|translate :
                                {start:(OfferDeatils?.start_at|date:'d MMMM yyyy')
                                ,end:(OfferDeatils?.end_at|date:'d MMMM yyyy')} }} </div>


                            <div class="offer_rate">
                                <span class="num">{{OfferDeatils?.rate ? OfferDeatils?.rate :'0.0'}}</span>
                                <ngx-stars [readonly]="true" [initialStars]="OfferDeatils?.rate || 0"
                                    [customStarIcons]="{empty:'/assets/images/icons/star_empty.svg',full:'/assets/images/icons/star.svg',half:'/assets/images/icons/star_half.svg'}"
                                    [color]="'#F8A02A'"></ngx-stars>
                                <span class="text"> {{!OfferDeatils?.rate ? ("offer.There are no reviews yet"|translate)
                                    :
                                    ("offer.Rate"|translate: {num:OfferDeatils?.rate_count})}}</span>
                            </div>


                            <div class="date">
                                {{"offer.Offer valid to"|translate}}:
                                <span>{{OfferDeatils?.end_at | date:'d MMMM yyyy'}}</span>
                            </div>

                            <div class="offer_branch">
                                {{"offer.Offer available in"|translate}}:

                                @switch (OfferDeatils?.for_all_branches) {
                                @case (true) {
                                <a (click)="OnViewBranches()">
                                    {{"offer.All branches"|translate}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                        <path id="ic_arrow_link"
                                            d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                            transform="translate(-212.5 759)" />
                                    </svg>

                                </a>
                                }
                                @case (false) {
                                <a (click)="OnViewBranches()">
                                    {{OfferDeatils?.number_of_branches|number}} {{"offer.Branches"|translate}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                        <path id="ic_arrow_link"
                                            d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                            transform="translate(-212.5 759)" />
                                    </svg>

                                </a>
                                }
                                }

                            </div>

                            @if (OfferDeatils?.user_use_offer && !OfferDeatils?.offer_end) {
                            @switch (OfferDeatils?.used_offer_info?.status) {
                            @case ('claimed') {
                            <div class="offer_climed">
                                <div class="text">
                                    <img src="/assets/images/icons/hand.svg" alt="hand image">
                                    {{"offer.Offer applied in"|translate}} :
                                    <span>{{OfferDeatils?.used_offer_info?.offersApprovedAt|date:'d MMMM yyyy - h:mm
                                        a'}}</span>
                                </div>

                                <div class="status-badge green">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="claimed-ic"
                                            d="M87.74-869.38l-1.935-1.935a.853.853,0,0,0-.63-.247.853.853,0,0,0-.63.247.854.854,0,0,0-.248.63.853.853,0,0,0,.248.63l2.565,2.565a.864.864,0,0,0,.63.27.863.863,0,0,0,.63-.27l5.085-5.085a.853.853,0,0,0,.248-.63.853.853,0,0,0-.248-.63.853.853,0,0,0-.63-.248.853.853,0,0,0-.63.248ZM89-862a8.764,8.764,0,0,1-3.51-.709,9.089,9.089,0,0,1-2.858-1.924,9.088,9.088,0,0,1-1.924-2.857A8.764,8.764,0,0,1,80-871a8.764,8.764,0,0,1,.709-3.51,9.089,9.089,0,0,1,1.924-2.858,9.089,9.089,0,0,1,2.858-1.924A8.763,8.763,0,0,1,89-880a8.763,8.763,0,0,1,3.51.709,9.089,9.089,0,0,1,2.858,1.924,9.089,9.089,0,0,1,1.924,2.858A8.764,8.764,0,0,1,98-871a8.764,8.764,0,0,1-.709,3.51,9.088,9.088,0,0,1-1.924,2.857,9.089,9.089,0,0,1-2.858,1.924A8.764,8.764,0,0,1,89-862Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,96.2-871a6.949,6.949,0,0,0-2.092-5.108A6.949,6.949,0,0,0,89-878.2a6.949,6.949,0,0,0-5.108,2.092A6.949,6.949,0,0,0,81.8-871a6.949,6.949,0,0,0,2.092,5.107A6.949,6.949,0,0,0,89-863.8ZM89-871Z"
                                            transform="translate(-80 880)" fill="#43a047" />
                                    </svg>

                                    {{"offer.Applied in vendor"|translate}}
                                </div>
                            </div>
                            }
                            @case ('redeemed') {
                            <div class="offer_climed">
                                <div class="text">
                                    <img src="/assets/images/icons/hand.svg" alt="hand image">
                                    {{"offer.Offer redeemed in"|translate}}:
                                    <span>{{OfferDeatils?.used_offer_info?.date|date:'d MMMM yyyy - h:mm a'}}</span>
                                </div>

                                <div class="status-badge yellow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="not-claimed-yet-ic"
                                            d="M84.5-869.65a1.3,1.3,0,0,0,.956-.394A1.3,1.3,0,0,0,85.85-871a1.3,1.3,0,0,0-.394-.956,1.3,1.3,0,0,0-.956-.394,1.3,1.3,0,0,0-.956.394,1.3,1.3,0,0,0-.394.956,1.3,1.3,0,0,0,.394.956A1.3,1.3,0,0,0,84.5-869.65Zm4.5,0a1.3,1.3,0,0,0,.956-.394A1.3,1.3,0,0,0,90.35-871a1.3,1.3,0,0,0-.394-.956A1.3,1.3,0,0,0,89-872.35a1.3,1.3,0,0,0-.956.394,1.3,1.3,0,0,0-.394.956,1.3,1.3,0,0,0,.394.956A1.3,1.3,0,0,0,89-869.65Zm4.5,0a1.3,1.3,0,0,0,.956-.394A1.3,1.3,0,0,0,94.85-871a1.3,1.3,0,0,0-.394-.956,1.3,1.3,0,0,0-.956-.394,1.3,1.3,0,0,0-.956.394,1.3,1.3,0,0,0-.394.956,1.3,1.3,0,0,0,.394.956A1.3,1.3,0,0,0,93.5-869.65ZM89-862a8.764,8.764,0,0,1-3.51-.709,9.089,9.089,0,0,1-2.857-1.924,9.089,9.089,0,0,1-1.924-2.857A8.764,8.764,0,0,1,80-871a8.764,8.764,0,0,1,.709-3.51,9.089,9.089,0,0,1,1.924-2.858,9.089,9.089,0,0,1,2.857-1.924A8.763,8.763,0,0,1,89-880a8.763,8.763,0,0,1,3.51.709,9.089,9.089,0,0,1,2.858,1.924,9.089,9.089,0,0,1,1.924,2.858A8.764,8.764,0,0,1,98-871a8.764,8.764,0,0,1-.709,3.51,9.089,9.089,0,0,1-1.924,2.857,9.089,9.089,0,0,1-2.858,1.924A8.764,8.764,0,0,1,89-862Zm0-1.8a6.949,6.949,0,0,0,5.107-2.092A6.949,6.949,0,0,0,96.2-871a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,89-878.2a6.949,6.949,0,0,0-5.107,2.092A6.949,6.949,0,0,0,81.8-871a6.949,6.949,0,0,0,2.093,5.107A6.949,6.949,0,0,0,89-863.8ZM89-871Z"
                                            transform="translate(-80 880)" fill="#f2cb00" />
                                    </svg>
                                    {{"offer.Not yet claimed"|translate}}
                                </div>
                            </div>}
                            }

                            }




                            @if (OfferDeatils?.user_use_offer && OfferDeatils?.offer_end) {
                            <div class="offer_climed">
                                <div class="text">
                                    <img src="/assets/images/icons/hand.svg" alt="hand image">
                                    {{"offer.Offer redeemed in"|translate}}:
                                    <span>{{OfferDeatils?.used_offer_info?.date|date:'d MMMM yyyy - h:mm a'}}</span>
                                </div>

                                <div class="status-badge red d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="error_FILL0_wght400_GRAD0_opsz24"
                                            d="M89-866.5a.871.871,0,0,0,.641-.259.871.871,0,0,0,.259-.641.871.871,0,0,0-.259-.641A.871.871,0,0,0,89-868.3a.871.871,0,0,0-.641.259.871.871,0,0,0-.259.641.871.871,0,0,0,.259.641A.871.871,0,0,0,89-866.5Zm0-3.6a.871.871,0,0,0,.641-.259A.871.871,0,0,0,89.9-871v-3.6a.871.871,0,0,0-.259-.641A.871.871,0,0,0,89-875.5a.871.871,0,0,0-.641.259.871.871,0,0,0-.259.641v3.6a.871.871,0,0,0,.259.641A.871.871,0,0,0,89-870.1Zm0,8.1a8.764,8.764,0,0,1-3.51-.709,9.089,9.089,0,0,1-2.857-1.924,9.089,9.089,0,0,1-1.924-2.857A8.764,8.764,0,0,1,80-871a8.764,8.764,0,0,1,.709-3.51,9.089,9.089,0,0,1,1.924-2.858,9.089,9.089,0,0,1,2.857-1.924A8.763,8.763,0,0,1,89-880a8.763,8.763,0,0,1,3.51.709,9.089,9.089,0,0,1,2.858,1.924,9.089,9.089,0,0,1,1.924,2.858A8.764,8.764,0,0,1,98-871a8.764,8.764,0,0,1-.709,3.51,9.089,9.089,0,0,1-1.924,2.857,9.089,9.089,0,0,1-2.858,1.924A8.764,8.764,0,0,1,89-862Zm0-1.8a6.949,6.949,0,0,0,5.107-2.092A6.949,6.949,0,0,0,96.2-871a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,89-878.2a6.949,6.949,0,0,0-5.107,2.092A6.949,6.949,0,0,0,81.8-871a6.949,6.949,0,0,0,2.093,5.107A6.949,6.949,0,0,0,89-863.8ZM89-871Z"
                                            transform="translate(-80 880)" fill="#e9580e" />
                                    </svg>

                                    {{"offer.We will not be able to apply the offer due to its expiration"|translate}}
                                </div>
                            </div>
                            }



                        </div>
                    </div>
                </div>

                <div class="sheet-actions">
                    @if (OfferDeatils?.user_use_offer && !OfferDeatils?.offer_end) {
                    @switch (OfferDeatils?.used_offer_info?.status) {
                    @case ('claimed') {
                    <button mat-button class="btn-primary w-100" (click)="OnViewQR_Code(OfferDeatils?.used_offer_info)">
                        {{"offer.View Details"|translate}}
                    </button>
                    <button mat-button class="btn-secondary" (click)="OnChangeFavoriteOffer()"
                        [class.loading]="fav_loading" [disabled]="fav_loading">
                        @switch (OfferDeatils?.favorite) {
                        @case (true) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                            <g id="favourite-filled-ic" transform="translate(0)">
                                <rect id="container" width="34" height="34" transform="translate(0)" fill="none" />
                                <path id="heart"
                                    d="M27.435,5.875a8.518,8.518,0,0,0-11.427-.938A8.508,8.508,0,0,0,4.114,6.316a9.506,9.506,0,0,0,.468,12.517l8.7,9.117a3.77,3.77,0,0,0,5.461,0l8.7-9.117a9.5,9.5,0,0,0,0-12.958Z"
                                    transform="translate(1.012 0.879)" fill="#dc3545" />
                            </g>
                        </svg>

                        }
                        @case (false) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
                            <path id="heart"
                                d="M27.435,5.875a8.518,8.518,0,0,0-11.427-.938A8.508,8.508,0,0,0,4.114,6.316a9.506,9.506,0,0,0,.468,12.517l8.7,9.117a3.77,3.77,0,0,0,5.461,0l8.7-9.117a9.5,9.5,0,0,0,0-12.958ZM25.461,16.81l-8.7,9.1a1.031,1.031,0,0,1-1.512,0l-8.7-9.147a6.5,6.5,0,0,1,0-8.795,5.791,5.791,0,0,1,8.4,0,1.357,1.357,0,0,0,1.989,0,5.791,5.791,0,0,1,8.4,0,6.5,6.5,0,0,1,.112,8.795Z"
                                transform="translate(-1.988 -3.121)" fill="#b0b0be" />
                        </svg>
                        }
                        }


                    </button>
                    }
                    @case ('redeemed') {
                    <button mat-button class="btn-primary w-100" (click)="OnViewQR_Code(OfferDeatils?.used_offer_info)">
                        {{"offer.View code"|translate}}
                    </button>
                    <button mat-button class="btn-secondary" (click)="OnChangeFavoriteOffer()"
                        [class.loading]="fav_loading" [disabled]="fav_loading">
                        @switch (OfferDeatils?.favorite) {
                        @case (true) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                            <g id="favourite-filled-ic" transform="translate(0)">
                                <rect id="container" width="34" height="34" transform="translate(0)" fill="none" />
                                <path id="heart"
                                    d="M27.435,5.875a8.518,8.518,0,0,0-11.427-.938A8.508,8.508,0,0,0,4.114,6.316a9.506,9.506,0,0,0,.468,12.517l8.7,9.117a3.77,3.77,0,0,0,5.461,0l8.7-9.117a9.5,9.5,0,0,0,0-12.958Z"
                                    transform="translate(1.012 0.879)" fill="#dc3545" />
                            </g>
                        </svg>

                        }
                        @case (false) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
                            <path id="heart"
                                d="M27.435,5.875a8.518,8.518,0,0,0-11.427-.938A8.508,8.508,0,0,0,4.114,6.316a9.506,9.506,0,0,0,.468,12.517l8.7,9.117a3.77,3.77,0,0,0,5.461,0l8.7-9.117a9.5,9.5,0,0,0,0-12.958ZM25.461,16.81l-8.7,9.1a1.031,1.031,0,0,1-1.512,0l-8.7-9.147a6.5,6.5,0,0,1,0-8.795,5.791,5.791,0,0,1,8.4,0,1.357,1.357,0,0,0,1.989,0,5.791,5.791,0,0,1,8.4,0,6.5,6.5,0,0,1,.112,8.795Z"
                                transform="translate(-1.988 -3.121)" fill="#b0b0be" />
                        </svg>
                        }
                        }


                    </button>
                    }
                    }
                    }

                    @if (!OfferDeatils?.user_use_offer) {
                    <button mat-button class="btn-primary w-100" (click)="OnRedeemOffer()">
                        {{"offer.Redeem offer"|translate}}
                    </button>
                    <button mat-button class="btn-secondary" (click)="OnChangeFavoriteOffer()"
                        [class.loading]="fav_loading" [disabled]="fav_loading">
                        @switch (OfferDeatils?.favorite) {
                        @case (true) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                            <g id="favourite-filled-ic" transform="translate(0)">
                                <rect id="container" width="34" height="34" transform="translate(0)" fill="none" />
                                <path id="heart"
                                    d="M27.435,5.875a8.518,8.518,0,0,0-11.427-.938A8.508,8.508,0,0,0,4.114,6.316a9.506,9.506,0,0,0,.468,12.517l8.7,9.117a3.77,3.77,0,0,0,5.461,0l8.7-9.117a9.5,9.5,0,0,0,0-12.958Z"
                                    transform="translate(1.012 0.879)" fill="#dc3545" />
                            </g>
                        </svg>

                        }
                        @case (false) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
                            <path id="heart"
                                d="M27.435,5.875a8.518,8.518,0,0,0-11.427-.938A8.508,8.508,0,0,0,4.114,6.316a9.506,9.506,0,0,0,.468,12.517l8.7,9.117a3.77,3.77,0,0,0,5.461,0l8.7-9.117a9.5,9.5,0,0,0,0-12.958ZM25.461,16.81l-8.7,9.1a1.031,1.031,0,0,1-1.512,0l-8.7-9.147a6.5,6.5,0,0,1,0-8.795,5.791,5.791,0,0,1,8.4,0,1.357,1.357,0,0,0,1.989,0,5.791,5.791,0,0,1,8.4,0,6.5,6.5,0,0,1,.112,8.795Z"
                                transform="translate(-1.988 -3.121)" fill="#b0b0be" />
                        </svg>
                        }
                        }


                    </button>
                    }


                </div>

            </ng-container>
        </div>
    </div>
</div>