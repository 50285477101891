<div class="side-sheet" [ngClass]="{'active': selectedId}">
    <div class="d-flex h-100">

        <div class="overlay" (click)="close.emit()"></div>

        <div class="sheet-container">
            <div class="sheet-head">
                <div class="title"> {{"vendor.Vendor Details"|translate}}</div>

                <button type="button" mat-icon-button (click)="close.emit()">
                    <img src="./assets/images/icons/ic_close.svg" alt="">
                </button>
            </div>

            <div *ngIf='dataLoading && selectedId' class="dataLoading">
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>


            <ng-container *ngIf="!dataLoading">

                <div class="sheet-content p-0">

                    <div class="vendorDetails" *ngIf="selectedId">

                        <div class="vendor_header">
                            <img class="cover_img" src="/assets/images/covers/vendor_profile.png" alt="Offer Image">
                            <div class="content">
                                <div class="vendor_logo">
                                    <img [src]="vendorDetails?.logo"
                                        onerror="this.src = './assets/images/defaults/vendors_default.svg'"
                                        (error)="vendorDetails!.logo = ''"
                                        [ngStyle]="{'height':vendorDetails?.logo ? '':'50%' }" alt="Vendor Logo">
                                    <!-- <span *ngIf="!vendorDetails?.logo">{{vendorDetails!.name | shortName}}</span> -->
                                </div>
                                <h5>{{vendorDetails?.name}}</h5>
                                <a *ngIf="!vendorDetails?.favorite" matRipple class="not_favorite"
                                    (click)="changeFavoriteStatus()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                        <g id="icons" transform="translate(-153 -13)">
                                            <path id="heart_outline_inactive"
                                                d="M20.165,5.028A6.225,6.225,0,0,0,12,4.378a6.209,6.209,0,0,0-8.5.954A6.43,6.43,0,0,0,3.84,14l6.212,6.312a2.752,2.752,0,0,0,3.9,0L20.165,14a6.428,6.428,0,0,0,0-8.971Zm-1.41,7.57-6.212,6.3a.753.753,0,0,1-1.08,0L5.251,12.568a4.4,4.4,0,0,1,0-6.089,4.228,4.228,0,0,1,6,0,.99.99,0,0,0,1.42,0,4.228,4.228,0,0,1,6,0,4.4,4.4,0,0,1,.08,6.089Z"
                                                transform="translate(151.012 9.879)" fill="#b0b0be" />
                                        </g>
                                    </svg>
                                    {{"vendor.Add to favorites"|translate}}
                                </a>
                                <a *ngIf="vendorDetails?.favorite" matRipple class="favorite"
                                    (click)="changeFavoriteStatus()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                        <g id="icons" transform="translate(-153 -13)">
                                            <path id="heart_filled_active"
                                                d="M20.165,5.028A6.225,6.225,0,0,0,12,4.378a6.209,6.209,0,0,0-8.5.954A6.43,6.43,0,0,0,3.84,14l6.212,6.312a2.752,2.752,0,0,0,3.9,0L20.165,14a6.428,6.428,0,0,0,0-8.971Z"
                                                transform="translate(151.012 9.879)" fill="#dc3545" />
                                        </g>
                                    </svg>

                                    {{"vendor.Favorite"|translate}}
                                </a>
                            </div>
                        </div>
                        <div class="socila_media" *ngIf="vendorDetails?.social_media_links?.facebook ||
                                                        vendorDetails?.social_media_links?.instagram||
                                                        vendorDetails?.social_media_links?.whatsapp||
                                                        vendorDetails?.social_media_links?.x || 
                                                        vendorDetails?.social_media_links?.snapchat ||
                                                        vendorDetails?.social_media_links?.tiktok">
                            <a class="item" *ngIf="vendorDetails?.social_media_links?.facebook"
                                [href]="vendorDetails?.social_media_links?.facebook" target="_blank">
                                <img src="/assets/images/icons/facebook.svg" alt="facebook">
                                <!-- {{"vendor.Facebook"|translate}} -->
                            </a>

                            <a class="item" *ngIf="vendorDetails?.social_media_links?.instagram"
                                [href]="vendorDetails?.social_media_links?.instagram" target="_blank">
                                <img src="/assets/images/icons/instagram.png" alt="instagram">
                                <!-- {{"vendor.Instagram"|translate}} -->
                            </a>
                            <a class="item" *ngIf="vendorDetails?.social_media_links?.x"
                                [href]="vendorDetails?.social_media_links?.x" target="_blank">
                                <img src="/assets/images/icons/twitter.svg" alt="twitter">
                                <!-- {{"vendor.X"|translate}} -->
                            </a>
                            <a class="item" *ngIf="vendorDetails?.social_media_links?.whatsapp"
                                [href]="'https://api.whatsapp.com/send?phone=966'+vendorDetails?.social_media_links?.whatsapp"
                                target="_blank">
                                <img src="/assets/images/icons/whatsApp.png" alt="WhatsApp">
                                <!-- {{"vendor_register.whatsApp"|translate}} -->
                            </a>
                            <a class="item" *ngIf="vendorDetails?.social_media_links?.snapchat"
                                [href]="vendorDetails?.social_media_links?.snapchat" target="_blank">
                                <img src="/assets/images/icons/snapchat-logo.svg" alt="snapchat">
                                <!-- {{"vendor_register.snapchat"|translate}} -->
                            </a>
                            <a class="item" *ngIf="vendorDetails?.social_media_links?.tiktok"
                                [href]="vendorDetails?.social_media_links?.tiktok" target="_blank">
                                <img src="/assets/images/icons/tiktok-app-icon.png" alt="tiktok">
                                <!-- {{"vendor_register.tiktok"|translate}} -->
                            </a>
                        </div>
                        <div class="vendor_info">
                            <div class="offers_branches">
                                <div class="card">
                                    <img class="icon" src="/assets/images/icons/offers_ison.svg" alt="">
                                    <div class="content">
                                        <div class="title"> {{"vendor.Vendor Offers"|translate}}</div>
                                        <ng-container *ngIf="vendorDetails?.num_offers">
                                            <div class="number">
                                                {{vendorDetails?.num_offers}}</div>
                                            <div class="btn_link"
                                                (click)="_config.goTo('/panel/vendors/offers/'+selectedId)">
                                                {{"vendor.List of offers"|translate}}

                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                    viewBox="0 0 12 12">
                                                    <path id="ic_arrow_link"
                                                        d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                                        transform="translate(-212.5 759)" opacity="0.8" />
                                                </svg>


                                            </div>
                                        </ng-container>


                                        <div class="text" *ngIf="!vendorDetails?.num_offers">
                                            {{"vendor.No offer for this Vendor"|translate}}</div>


                                    </div>
                                </div>
                                <div class="card">
                                    <img class="icon" src="/assets/images/icons/branches_icon.svg" alt="">
                                    <div class="content">
                                        <div class="title"> {{"vendor.Vendor Branches"|translate}}</div>
                                        <ng-container *ngIf="vendorDetails?.num_branches">
                                            <div class="number">
                                                {{vendorDetails?.num_branches}}</div>
                                            <div class="btn_link" (click)="OnViewBranches()">
                                                {{"vendor.List of branches"|translate}}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                    viewBox="0 0 12 12">
                                                    <path id="ic_arrow_link"
                                                        d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                                        transform="translate(-212.5 759)" opacity="0.8" />
                                                </svg>

                                            </div>
                                        </ng-container>


                                        <div class="text" *ngIf="!vendorDetails?.num_branches">
                                            {{"vendor.No branch for this Vendor"|translate}}</div>

                                    </div>
                                </div>
                            </div>

                            <section class="info">
                                <div class="title"> {{"vendor.Vendor address and contact details"|translate}}</div>
                                <div class="items">
                                    <div class="item" *ngIf="vendorDetails?.address || vendorDetails?.map_lat">
                                        <div class="icon">
                                            <img src="/assets/images/icons/location.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"> {{"vendor.Vendor Address"|translate}}</div>
                                            <div class="lable">
                                                <span> {{vendorDetails?.address}}</span>
                                                <div class="mt-3">
                                                    <google-map height="200px" width="100%" [options]="mapOptions">
                                                        <map-marker [options]="marker"></map-marker>
                                                    </google-map>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="item" *ngIf="vendorDetails?.phone">
                                        <div class="icon">
                                            <img src="/assets/images/icons/mobile.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"> {{"vendor.Mobile Number"|translate}}</div>
                                            <div class="lable">
                                                <span class="mb-3"><bdi>(+966) {{vendorDetails?.phone}}</bdi></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item" *ngIf="vendorDetails?.email">
                                        <div class="icon">
                                            <img src="/assets/images/icons/@_Icon.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"> {{"vendor.E-mail"|translate}}</div>
                                            <div class="lable">
                                                <a class="mb-3"
                                                    href="mailto:{{vendorDetails?.email}}">{{vendorDetails?.email}}</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item" *ngIf="vendorDetails?.website">
                                        <div class="icon">
                                            <img src="/assets/images/icons/website.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"> {{"vendor_register.website"|translate}}</div>
                                            <div class="lable">
                                                <a class="mb-3" [href]="vendorDetails?.website"
                                                    target="_blank">{{vendorDetails?.website}}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                            <section class="info">
                                <div class="title"> {{"vendor.Vendor Categories"|translate}}</div>


                                <mat-accordion class="Custom-accordion" [hideToggle]="true"
                                    *ngFor="let mainCategory of vendorDetails?.main_category; let i = index">
                                    <!-- <mat-expansion-panel class="mb-2" (opened)="getSubCategories(mainCategory.id, i)"> -->
                                    <mat-expansion-panel class="mb-2" (opened)="mainCategory.opened = true"
                                        (closed)="mainCategory.opened  =false">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="name-with-image">
                                                    <div class="image-square">
                                                        <img [src]="mainCategory?.logo"
                                                            onerror="this.src = './assets/images/defaults/categories_default.svg'"
                                                            (error)="mainCategory.logo = ''"
                                                            [ngStyle]="{'object-fit':mainCategory?.logo ? '' :'scale-down' , 'padding':mainCategory?.logo ? '0px' : '5px'}">
                                                        <!-- <span *ngIf="!mainCategory.logo">
                                                            {{ mainCategory.name | shortName }}</span> -->
                                                    </div>
                                                    <div class="name">
                                                        <h6 class="">{{ mainCategory.name }}</h6>
                                                        <p> <span>{{
                                                                mainCategory?.child?.length?mainCategory?.child?.length:0
                                                                }}</span>
                                                            {{ "vendor.Subcategories"|translate}}</p>
                                                    </div>
                                                </div>

                                                <span>
                                                    <svg id="Collapse_Expand_icon"
                                                        [class.opened]="!mainCategory?.opened"
                                                        data-name="Collapse + Expand" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="collapse-icon"
                                                            d="M15.37,12.76a.87.87,0,0,1,1.26,0l3.93,3.93a.833.833,0,0,1,.24.615.88.88,0,0,1-.27.645.983.983,0,0,1-.63.255.812.812,0,0,1-.63-.255L16,14.68l-3.3,3.3a.833.833,0,0,1-.615.24.88.88,0,0,1-.645-.27.87.87,0,0,1,0-1.26ZM16,4a12.171,12.171,0,0,0-4.74.915A11.614,11.614,0,0,0,4.915,11.26,12.171,12.171,0,0,0,4,16a12.093,12.093,0,0,0,.915,4.71A11.712,11.712,0,0,0,7.45,24.52,11.964,11.964,0,0,0,16,28,11.9,11.9,0,0,0,28,16a11.964,11.964,0,0,0-3.48-8.55,11.712,11.712,0,0,0-3.81-2.535A12.093,12.093,0,0,0,16,4ZM16,16ZM16,5.8A9.994,9.994,0,0,1,26.2,16,9.994,9.994,0,0,1,16,26.2,9.994,9.994,0,0,1,5.8,16,9.994,9.994,0,0,1,16,5.8Z"
                                                            transform="translate(-4 -4)" />
                                                    </svg>
                                                </span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <!-- <div *ngIf='RequestDetailes.main_category[i].loading' class="dataLoading">
                                            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                                        </div> -->

                                        <!-- <ng-container *ngIf="!RequestDetailes.main_category[i].loading"> -->
                                        <ul>
                                            <li *ngFor="let subCategory of mainCategory.child">
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5"
                                                    viewBox="0 0 5 5">
                                                    <circle id="circle" cx="2.5" cy="2.5" r="2.5" />
                                                </svg> -->

                                                <span>{{subCategory.name}}</span>
                                            </li>
                                        </ul>
                                        <!-- </ng-container> -->
                                        <!-- <div
                                            *ngIf='!RequestDetailes.main_category[i].loading && !RequestDetailes.main_category[i].subCategoris?.length'>
                                            <p class="text-center font-14 fontRegular grey-color">
                                                {{ 'general.No Data Found' | translate }}
                                            </p>
                                        </div> -->
                                    </mat-expansion-panel>
                                </mat-accordion>


                            </section>



                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>