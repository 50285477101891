import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from '../../environments/environment';
import { ConfigService } from '../helpers/config.service';
import { AuthenticationService } from './authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { OfferQRCodeComponent } from '../shared/offer-card/offer-details/offer-qr-code/offer-qr-code.component';


export enum SocketEventType {
  Notification = "notification",
  Redeemed_Offers = "redeemed-offers",
  Redeemed_Points = "redeemed-points",
  User_Points = "user-points",
}


interface SocketEvent<TDataShape> {
  type: SocketEventType,
  data?: any
}


@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket: any;
  token!: string;
  private socketEvent!: Subject<any>;

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  private ListOfListener: string[] = [];
  constructor(
    public _config: ConfigService,
    private _authService: AuthenticationService,
    private _dialog: MatDialog
  ) {
    this.socketEvent = new Subject<any>();

  }


  onOpenSocket() {
    this.token = localStorage.getItem('token') || '';


    this.socket = io(environment.socketUrl, {
      transports: ['websocket'],
      auth: {
        token: `${this.token}`,
      }
    });
  }

  onCloseSocket() {
    this.socket.disconnect();
    this.socket.close();
  }


  setupSocketEvents() {
    this.socket?.on('connect', (data: any) => {
      console.log("Socket ID is: " + this.socket.id);
      this.RegisterToSocket(this.socket.id);
      this.socket?.on(SocketEventType.Notification, (data: any) => {
        this.onMessage({ type: SocketEventType.Notification, data });
      });

      this.socket?.on(SocketEventType.Redeemed_Offers, (data: any) => {
        this.onMessage({ type: SocketEventType.Redeemed_Offers, data });
      });

      this.socket?.on(SocketEventType.Redeemed_Points, (data: any) => {
        this.onMessage({ type: SocketEventType.Redeemed_Points, data });
      });

      this.socket?.on(SocketEventType.User_Points, (data: any) => {
        this.onMessage({ type: SocketEventType.User_Points, data });
      });
    })
  }





  // public sendMessage(message:any) {
  //   this.socket.emit('message', message);
  // }

  // public getNewMessage = () => {
  //   this.socket.on('message', (message) =>{
  //     this.message$.next(message);
  //   });

  //   return this.message$.asObservable();
  // };

  private onMessage<TDataShape>(payload: SocketEvent<TDataShape>) {
    this.socketEvent.next(payload);
  }

  getDataStream<TDataShape>(type: SocketEventType | string): Observable<SocketEvent<TDataShape>> {
    return this.socketEvent.asObservable().pipe(filter(event => type === event.type))
  }


  RegisterToSocket(socketId: string) {
    // let my = macadress
    // my.one(function (err, mac) {
    //   console.log("Mac address for this host: %s", mac);  
    // });
    const model = {
      type: "web",
      socket_id: socketId,
      mac_address: (Math.random() * 1000000000000000).toString()
    }
    this._authService.ConnectToSocket(model).subscribe((response) => {
      console.log("Clinet registered successfully", response);
    })
  }

  OpenOfferClaimedDetails(OfferID: string) {
    this._dialog.closeAll();
    // this._dialog.afterAllClosed.subscribe(res => {
      const dialogRef = this._dialog.open(OfferQRCodeComponent, {
        width: "540px",
        data: { Offer_ID: OfferID }
      })
      // dialogRef.afterClosed().subscribe((res: any) => {
        // if (res) {
        //   this.GetOfferDetails()
        // }
      // })
    // })
  }
}
