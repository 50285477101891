export function formatParams(params: { [key: string]: any }): string {
    const encodedParams = Object.entries(params)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map((item: any) => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`).join('&');
            } else {
                return value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : '';
            }
        })
        .join('&');

    return `?${encodedParams}`;
}