import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sliceFullname'
})
export class SliceNamePipe implements PipeTransform {

    transform(name: string) {
        return name.split(' ').slice(0, 2).join(' ');
    }
}