import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from './config.service';

@Pipe({
  name: 'metertToKilometer'
})
export class MetertToKilometerPipe implements PipeTransform {
  constructor(private _config: ConfigService) { }
  transform(value: number): string {
    if (value > 1000) {
      return (value / 1000).toFixed(1) + this._config._translate.instant('general.km');
    } else {
      return value.toString() + this._config._translate.instant('general.m');
    }
  }

}
