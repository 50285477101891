import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../services/api-service.service';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { formatParams } from '../../../helpers/FormateArrayToParams';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  constructor(private _apiService: ApiServiceService) { }


  GetOffersList(model: any) {
    let params = formatParams(model)
    return this._apiService.get(`${environment.apiUrl}/web/offers${params}`)
      .pipe(catchError(this.handleError));
  }

  GetCategoriesWithSub() {
    return this._apiService.get(`${environment.apiUrl}/web/categories-with-sub?perPage=50`)
      .pipe(catchError(this.handleError));
  }

  GetMainCategories(model?:any) {
    return this._apiService.get(`${environment.apiUrl}/web/categories/main`,model)
      .pipe(catchError(this.handleError));
  }


  GetSubCategories(ID: string) {
    return this._apiService.get(`${environment.apiUrl}/web/categories/sub/${ID}`)
      .pipe(catchError(this.handleError));
  }


  GetOfferDetails(ID: string) {
    return this._apiService.get(`${environment.apiUrl}/web/offers/${ID}`)
      .pipe(catchError(this.handleError));
  }

  GetOfferBranches(ID: string) {
    return this._apiService.get(`${environment.apiUrl}/web/offers/branchs/${ID}`)
      .pipe(catchError(this.handleError));
  }

  RedeemOffer(ID: string) {
    return this._apiService.post(`${environment.apiUrl}/web/offers/redeem/${ID}`)
      .pipe(catchError(this.handleError));
  }

  OnChangeFevorateoffers(model: any) {
    return this._apiService.post(`${environment.apiUrl}/web/offers/favorite`, model)
      .pipe(catchError(this.handleError));
  }

  RateOffer(model: any) {
    return this._apiService.post(`${environment.apiUrl}/web/offers/offer-rate`, model)
      .pipe(catchError(this.handleError));
  }
  handleError(error: any) {
    return throwError(error);
  }
}
