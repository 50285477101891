// import { MatPaginatorIntl } from '@angular/material/paginator';

const lang = localStorage.getItem('language') || 'ar'

// export function CustomPaginator() {

//   const customPaginatorIntl = new MatPaginatorIntl();

//   customPaginatorIntl.itemsPerPageLabel = lang == 'ar' ? 'عدد المدخلات المعروضة ' : 'Number of displayed Entries';
//   customPaginatorIntl.getRangeLabel = RangeLabel;

//   return customPaginatorIntl;
// }

// const RangeLabel = (page: number, pageSize: number, length: number) => {
//   if (length == 0 || pageSize == 0) { return `0 ` + lang == 'ar' ? 'من' : 'of' + ` ${length}`; }

//   length = Math.max(length, 0);

//   const startIndex = page * pageSize;

//   // If the start index exceeds the list length, do not try and fix the end index to the end.
//   const endIndex = startIndex < length ?
//       Math.min(startIndex + pageSize, length) :
//       startIndex + pageSize;

//   return `${startIndex + 1} - ${endIndex} ${lang == 'ar' ? 'من' : 'of'} ${length}`;
// }


import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {

  override itemsPerPageLabel = lang == 'ar' ? 'عدد المدخلات المعروضة ' : 'Number of displayed entries'
  override nextPageLabel = lang == 'ar' ? 'الصفحة القادمة' : 'Next Page';
  override previousPageLabel = lang == 'ar' ? 'الصفحة السابقة' : 'Previous Page';

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 ` + lang == 'ar' ? 'من' : 'of' + ` ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${lang == 'ar' ? 'من' : 'of'} ${length}`;
  };
}
